import React, { useContext, useEffect, useState } from 'react';
import {
    FooterContainer,
    StyledLink,
    IconsContainer,
    InfoText,
    FooterAnchor,
    StyledContainer,
    DepartmentsWrapper,
    Wrapper,
    LinksColumn,
    InvoiceColumn,
    ContactColumn,
    EmailLink,
    SocialLink,
    SocialImage,
    RightsReserved, PhoneLink,
} from './FooterStyles';
import AppContext from '../../context/app-context';
import Contact from './Contact/Contact';
import { customerServiceOfficeData } from './footerContent';

export default function Footer() {
    const { routeMap, lang, page } = useContext(AppContext);
    const [isFooterHidden, setIsFooterHidden] = useState(false);

    useEffect(() => {
        setIsFooterHidden(page === 'offer');
        // eslint-disable-next-line
    }, [page, lang]);

    return !isFooterHidden && (
        <FooterContainer>
            <FooterAnchor id="kontakt" />
            <StyledContainer>
                <DepartmentsWrapper>
                    <Contact
                        deparmentName={customerServiceOfficeData.name}
                        employees={customerServiceOfficeData.employees}
                    />
                </DepartmentsWrapper>
                <InfoText>
                    Biuro czynne od poniedziałku do piątku w godzinach 8:30 - 16:00
                </InfoText>
                <Wrapper>
                    <LinksColumn>
                        <StyledLink to={`${routeMap.products.path[lang]}`}>Produkty</StyledLink>
                        {/*<StyledLink to={`${routeMap.products_for_preschool.path[lang]}`}>Dla przedszkoli</StyledLink>*/}
                        {/*<StyledLink to={`${routeMap.products_for_school.path[lang]}`}>Dla szkół</StyledLink>*/}
                        <StyledLink to={`${routeMap.courses.path[lang]}`}>Szkolenia</StyledLink>
                        {/*<StyledLink to={`${routeMap.company.path[lang]}`}>Firma</StyledLink>*/}
                        <StyledLink to={`${routeMap.faq.path[lang]}`}>Pomoc</StyledLink>
                    </LinksColumn>
                    <LinksColumn>
                        {/*<StyledLink to={`${routeMap.labs.path[lang]}`}>Laboratoria przyszłości</StyledLink>*/}
                        <StyledLink to={`${routeMap.regulations.path[lang]}`}>Regulamin</StyledLink>
                        <StyledLink to={`${routeMap.certifications.path[lang]}`}>Certyfikaty</StyledLink>
                        <StyledLink to={`${routeMap.warranty.path[lang]}`}>Gwarancja</StyledLink>
                        <StyledLink to={`${routeMap.privacy.path[lang]}`}>Polityka prywatności</StyledLink>
                        {/*<StyledLink to={`${routeMap.ue_projects.path[lang]}`}>Projekty Unijne</StyledLink>*/}
                    </LinksColumn>
                    <InvoiceColumn>
                        Dane do faktury:
                        <br />
                        EduSense SA
                        <br />
                        ul. Kieturakisa 10/7
                        <br />
                        80-742 Gdańsk
                        <br />
                        NIP: 946-265-72-65
                    </InvoiceColumn>
                    <ContactColumn>
                        <PhoneLink href="tel:+48585055914">
                            tel. 585 055 914
                        </PhoneLink>
                        <EmailLink href="mailto:kontakt@edu-sense.pl?subject=Kontakt ze strony www">
                            kontakt@edu-sense.pl
                        </EmailLink>
                        <IconsContainer>
                            <SocialLink
                                href="https://www.facebook.com/UczymyDzieciProgramowac/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <SocialImage
                                    src={`${process.env.REACT_APP_CDN_URL}facebook_01.svg`}
                                    alt="Facebook"
                                    title="Znajdź nas na Facebooku!"
                                />
                            </SocialLink>
                            <SocialLink
                                href="https://www.instagram.com/edu_sense_pl/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <SocialImage
                                    src={`${process.env.REACT_APP_CDN_URL}instagram_01.svg`}
                                    alt="Instagram"
                                    title="Znajdź nas na Instagramie!"
                                />
                            </SocialLink>                            <SocialLink
                                href="https://www.youtube.com/channel/UCd3KlH_Z77XZIpVAhwKzdNg"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <SocialImage
                                    src={`${process.env.REACT_APP_CDN_URL}youtube_01.svg`}
                                    alt="Kanał YouTube"
                                    title="Zobacz nasz kanał na YouTube!"
                                />
                            </SocialLink>
                        </IconsContainer>
                    </ContactColumn>
                </Wrapper>
                <RightsReserved>
                    2016-
                    {(new Date()).getFullYear()}
                    {' '}
                    © EDUSENSE SA WSZELKIE PRAWA ZASTRZEŻONE
                </RightsReserved>
            </StyledContainer>
        </FooterContainer>
    );
}
