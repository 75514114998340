import React, { useEffect, useRef, useState } from 'react';
import { getDiscountByCode } from '../../api/Discount/Discount';
import {
    Button, Input, Message, Wrapper, RemoveButton, MessageContainer, DiscountCode, DiscountValue,
} from './DiscountStyles';
import useCart from '../Cart/hook/useCart';

export default function Discount({
    className, handleSetDiscount, handleRemoveDiscount, value, code, readOnly, cartTotalPrice,
}) {
    const [triggerRefresh, setTriggerRefresh] = useState(false);
    const [discountCode, setDiscountCode] = useState(code);
    const [discountError, setDiscountError] = useState(null);
    const [discountMessage, setDiscountMessage] = useState(null);
    const {
        loadDiscount, getItems, itemsCount, getTotalPrice,
    } = useCart(null);

    useEffect(() => {
        if (code) {
            setDiscountCode(code);
            setTriggerRefresh((t) => !t);
        }
    }, [code]);

    const getDiscountMessage = () => {
        const discount = loadDiscount();
        const itemsInCartIds = getItems().map((item) => item.id);
        if (!discount || !itemsInCartIds) {
            return '';
        }
        const discountProducts = discount?.products.map((product) => product);
        const isProductInCart = (product) => itemsInCartIds.indexOf(product?.product.id) > -1;
        const discountProductsInCart = discountProducts.filter(isProductInCart);
        if (discount.value === 0 && discountProductsInCart.length === 0) {
            return 'Podany kod rabatowy nie odpowiada żadnemu z\u00A0produktów w koszyku';
        }
        if (discountProductsInCart.length === 0 && discount.value > 0) {
            return `Otrzymujesz ${discount.value}% rabatu na wszystkie produkty`;
        }
        const productDiscountsValues = discountProductsInCart.map((discount) => discount.value);
        const getNumberOfProductsWithBasicDiscount = () => {
            if (discount.value === 0) return 0;
            return itemsInCartIds.length - discountProductsInCart.length;
        };
        const getDiscountValuesInCart = () => {
            if (getNumberOfProductsWithBasicDiscount() > 0) {
                return [...productDiscountsValues, discount.value];
            }

            return [...productDiscountsValues];
        };
        const discountValuesInCart = getDiscountValuesInCart();
        const minDiscountValue = Math.min(...discountValuesInCart);
        const maxDiscountValue = Math.max(...discountValuesInCart);
        const areDiscountsPercentage = discountProductsInCart[0].is_percentage;
        const discountValue = getTotalPrice() - cartTotalPrice;
        if (!areDiscountsPercentage) {
            return `Otrzymujesz ${parseInt(Math.round(discountValue), 10)}zł rabatu`;
        }
        if (minDiscountValue === maxDiscountValue) {
            return `Otrzymujesz ${minDiscountValue}% rabatu`;
        }

        // return "Podany kod rabatowy nie odpowiada żadnemu z produktów w koszyku"
        return `Otrzymujesz ${minDiscountValue}-${maxDiscountValue}${areDiscountsPercentage ? '%' : 'zł'} rabatu`;
    };

    useEffect(() => {
        if (discountCode && discountCode.length) {
            setDiscountMessage(prepareDiscountProfitMessage(discountCode, value));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [triggerRefresh]);

    useEffect(() => {
        if (value !== null) {
            setDiscountMessage(prepareDiscountProfitMessage(discountCode, value));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    useEffect(() => {
        const discount = loadDiscount();
        if (discount) {
            handleDiscountButton();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (value !== null) {
            setDiscountMessage(prepareDiscountProfitMessage(discountCode, value));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemsCount]);

    const setError = (error) => {
        setDiscountMessage(null);
        setDiscountError(error);
    };

    const handleDiscountButton = () => {
        if (!discountCode || discountCode.length <= 0) return;
        setDiscountMessage('Sprawdzanie kodu rabatowego...');
        getDiscountByCode(discountCode).then((discount) => {
            if (discount.id) {
                if (discount.expire_at && new Date(discount.expire_at) < new Date()) {
                    setError('Kod rabatowy stracił ważność');
                    return;
                }

                if (discount.used === true) {
                    setError('Kod rabatowy został już użyty');
                    return;
                }

                handleSetDiscount(discount);
                setTriggerRefresh(!triggerRefresh);
                return;
            }
            setError('Podaj poprawny kod rabatowy');
        }).catch(() => setError('Podaj poprawny kod rabatowy'));
    };

    const buttonRef = useRef();

    useEffect(() => {
        const downHandler = (e) => buttonRef.current && e.key === 'Enter' && buttonRef.current.click();
        window.addEventListener('keydown', downHandler);
        return () => window.removeEventListener('keydown', downHandler);
    }, []);

    const removeClickHandler = () => {
        handleRemoveDiscount();
        setDiscountCode(null);
        setDiscountError(null);
        setDiscountMessage(null);
    };

    const prepareDiscountProfitMessage = (code) => (
        <div className="d-flex flex-column">
            <DiscountCode>
                {code}
            </DiscountCode>
            <DiscountValue>
                {getDiscountMessage()}
            </DiscountValue>
        </div>
    );

    return (
        <Wrapper className={className}>
            {discountMessage || readOnly
                ? (
                    <MessageContainer>
                        <Message>{discountMessage || '-'}</Message>
                        {loadDiscount() && !readOnly
                            && (
                                <RemoveButton type="button" onClick={(e) => removeClickHandler(e)}>
                                    <i className="fa fa-times" aria-hidden="true" />
                                </RemoveButton>
                            )}
                    </MessageContainer>
                )
                : (
                    <>
                        <Input
                            onChange={(value) => setDiscountCode(value)}
                            id="discount"
                            label="kod rabatowy"
                            isInvalid={discountError !== null}
                            feedback={discountError}
                            required={false}
                            placeholder="wpisz kod rabatowy"
                            onBlur={handleDiscountButton}
                        />
                        <Button
                            ref={buttonRef}
                            onClick={handleDiscountButton}
                            error={discountError !== null ? 1 : 0}
                        >
                            OK
                        </Button>
                    </>
                )}
        </Wrapper>
    );
}
