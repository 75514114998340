import React, {useEffect} from 'react';
import {UEProjectsWrapper} from './UEProjectsStyles';
import {ImageContainer, PatronImage} from "../../Footer/FooterStyles";

export default function UEProjects() {
    useEffect(() => window.fbq('track', 'PageView'), []);

    return (
        <UEProjectsWrapper className="font-dark-blue">
            <h1 className="font-weight-bold mt-5 mb-3">Projekty Unijne</h1>
            <ImageContainer>
                <PatronImage src="/image/FE_POIR_poziom_pl-1_rgb.jpg" alt="" />
            </ImageContainer>
            <p>
                EduSense SA realizuje projekt „EduSense Editor – silnik do edycji scenariuszy gier z wykorzystaniem
                robota edukacyjnego”, w ramach Działania 1.2 „Sektorowe Programy B+R” Programu Operacyjnego Inteligentny
                Rozwój 2014-2020, współfinansowany ze środków Europejskiego Funduszu Rozwoju Regionalnego.
            </p>
            <p>
                EduSense SA realizuje projekt „EduPython – platforma do nauki programowania z wykorzystaniem gry
                edukacyjnej”, w ramach Działania 1.2 „Sektorowe Programy B+R” Programu Operacyjnego Inteligentny Rozwój
                2014-2020, współfinansowany ze środków Europejskiego Funduszu Rozwoju Regionalnego.
            </p>
            <p>
                EduSense SA realizuje projekt „LITO (artIficiaL InTelligence tOy) - robot i nowatorska metoda nauczania
                o problematyce sztucznej inteligencji i programowaniu z elementami obsługi sieci neuronowych”, w ramach
                Poddziałania 1.1.1 Programu Operacyjnego Inteligentny Rozwój 2014-2020, współfinansowany ze środków
                Europejskiego Funduszu Rozwoju Regionalnego.
            </p>
            <ImageContainer>
                <PatronImage src="/image/FE_POIR_poziom_engl-1_rgb.jpg" alt="" />
            </ImageContainer>
            <p>
                EduSense SA is implementing the project "EduSense Editor - an engine for editing game scenarios using an
                educational robot", under Action 1.2 "Sectoral R&D Programmes" of the Operational Programme Smart Growth
                2014-2020, co-financed by the European Regional Development Fund.
            </p>
            <p>
                EduSense SA is implementing the project 'EduPython - a platform for learning programming with the use of
                an educational game', under Action 1.2 'Sectoral R&D Programmes' of the Operational Programme Smart
                Growth 2014-2020, co-financed by the European Regional Development Fund.
            </p>
            <p>
                EduSense SA is implementing the project 'LITO (artIficiaL InTelligence tOy) - a robotic and innovative
                teaching method on artificial intelligence and programming issues with elements of neural network
                operation', under Sub-Action 1.1.1 of the Operational Programme Smart Growth 2014-2020, and co-financed
                by the European Regional Development Fund.
            </p>
        </UEProjectsWrapper>
    );
}
