import React from 'react';
import {
    Contact, ContactDescription, Email,
    InfoColumn, InfoWrapper, Paragraph, Phone, StyledLink,
} from './InfoSectionStyles';

const InfoSection = () => (
    <InfoWrapper>
        <InfoColumn>
            <StyledLink
                href="/aktywna_tablica_wnioski.zip"
                target="_blank"
                rel="noreferrer"
            >
                Pobierz wzory wniosków z przykładowym uzupełnieniem
            </StyledLink>
            <Paragraph className="mt-3">
                Celem Program Aktywna Tablica 2023 jest wyposażenie szkół w nowoczesne technologie,
                pomoce dydaktyczne zgodnie z ich potrzebami. Program Aktywna Tablica wspiera rozwijanie kompetencji
                cyfrowych uczniów w zakresie technologii informacyjno-komunikacyjnych.
            </Paragraph>
            <Paragraph>
                <strong>Harmonogram</strong>
                15 maja 2023 -> Złożenie przez dyrektorów szkół wniosków o udział w programie do organów prowadzących
                30 maja 2023 -> Złożenie wniosków przez organ prowadzący do wojewodów
                30 czerwca 2023 -> Proces kwalifkacyjny złożonych wniosków
                31 sierpnia 2023 -> Przekazanie środków przez wojewodów organom prowadzącym
            </Paragraph>
            <Paragraph>
                <strong>Uwaga!</strong> Termin składania wniosków upływa 15 maja 2023
            </Paragraph>
        </InfoColumn>
        <InfoColumn>
            <Paragraph>
                <strong>14 000 zł</strong>
                <br/>
                Szkoły podstawowe i Szkoły za granicą, które nie otrzymały wsparcia w latach 2017-2019 oraz Szkoły ponadpodstawowe
                <br/>
                <strong>35 000 zł</strong>
                <br/>
                Szkoły podstawowe w których uczą sie uczniowie ze specjalnymi potrzebami edukacyjnymi (posiadający
                różnorodne zaburzenia rozwojowe, utrudniające lub uniemożliwiające prawidłowy proces kształcenia)
                oraz Szkoły podstawowe dla dzieci niewidomych
                <br/>
                <strong>100 000 zł</strong>
                <br/>
                Specjalne Ośrodki Szkolno-Wychowawcze dla uczniów niewidomych i słabowidzących (SOSW)
                <br/>
            </Paragraph>
            <Paragraph>
                Wniosek można złożyć tylko raz, tym samym warto wnioskować o maksymalną kwotę dofnansowania.
            </Paragraph>
            <Paragraph style={{fontSize: 20, fontWeight: 'bold'}}>
                Jeśli zastanawiasz się w jaki sposób skorzystać z programu i jak poprawnie wypełnić wniosek, skontaktuj się z nami.
            </Paragraph>
            <Contact>
                <div>
                    <ContactDescription>Zadzwoń do nas</ContactDescription>
                    <Phone href="tel:+48585055914">+48 585 055 914</Phone>
                </div>
                <div>
                    <ContactDescription>Napisz do nas</ContactDescription>
                    <Email
                        href="mailto:kontakt@edu-sense.pl?subject=AktywnaTablica"
                    >
                        kontakt@edu-sense.pl
                    </Email>
                </div>
            </Contact>
        </InfoColumn>
    </InfoWrapper>
);

export default InfoSection;
