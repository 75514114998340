import React from 'react';
import {
    Contact, ContactDescription, Email, ImportantText,
    InfoColumn, InfoWrapper, Paragraph, Phone, StyledLink,
} from './InfoSectionStyles';

const InfoSection = () => (
    <InfoWrapper>
        <InfoColumn>
            <StyledLink
                href="https://www.gov.pl/web/laboratoria/szkoly-w-programie"
                target="_blank"
                rel="noreferrer"
            >
                SPRAWDŹ CZY TWOJA SZKOŁA BIERZE UDZIAŁ W PROJEKCIE
            </StyledLink>
            <Paragraph>
                Każda Szkoła, która do 30 listopada wypełniła zgłoszenie i otrzymała
                dofinansowanie ciągle ma możliwość zakupu pomocy dydaktycznych z puli
                wyposażenia dodatkowego.
            </Paragraph>
            <Paragraph>
                Środki można wydawać maksymalnie do 31 sierpnia 2022 r.
                <br />
                <strong>Uwaga! </strong>
                Otrzymane wsparcie nie będzie wymagało wniesienia wkładu własnego
                i przyznawane będzie w całości z góry.
            </Paragraph>
        </InfoColumn>
        <InfoColumn>
            <Paragraph>
                <ImportantText>LABORATORIA PRZYSZŁOŚCI </ImportantText>
                to inicjatywa edukacyjna realizowana przez Ministerstwo Edukacji
                i Nauki we współpracy z Centrum GovTech w Kancelarii Prezesa Rady Ministrów.
            </Paragraph>
            <Paragraph>
                Jej celem jest wsparcie wszystkich szkół podstawowych w budowaniu wśród uczniów
                kompetencji przyszłości z tzw. kierunków STEAM (nauka, technologia, inżynieria,
                sztuka oraz matematyka). W ramach Laboratoriów Przyszłości organy prowadzące szkoły
                otrzymają od państwa wsparcie finansowe, dzięki któremu polscy uczniowie będą mogli
                uczyć się poprzez eksperymentowanie i zdobywać w ten sposób praktyczne umiejętności.
            </Paragraph>
            <Paragraph>
                Nie czekaj, skontaktuj się z nami już dziś. Przygotujemy spersonalizowaną propozycję,
                idealną dla potrzeb Twojej placówki.
            </Paragraph>
            <Contact>
                <div>
                    <ContactDescription>Zadzwoń do nas</ContactDescription>
                    <Phone href="tel:+48585055975">+48 585 055 975</Phone>
                </div>
                <div>
                    <ContactDescription>Napisz do nas</ContactDescription>
                    <Email
                        href="mailto:oferty@edu-sense.pl?subject=Laboratoria przyszłości"
                    >
                        oferty@edu-sense.pl
                    </Email>
                </div>
            </Contact>
        </InfoColumn>
    </InfoWrapper>
);

export default InfoSection;
