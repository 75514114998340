import { Form } from 'react-bootstrap';
import React from 'react';
import { Wrapper } from './TermsCheckboxStyles';
import { WarningMessage } from '../Input/InputStyles';

export default function TermsCheckbox({ className, inputRef }) {
    return (
        <Wrapper className={className} type="checkbox" id="terms">
            <Form.Check.Input type="checkbox" required ref={inputRef} />
            <Form.Check.Label>
                <span className="placeholder" />
                <span style={{ display: 'inline-block', paddingRight: '30px' }} className="label">
                    Akceptuję
                    {' '}
                    <a href="/pl/regulamin" target="_blank" rel="noopener noreferrer">Regulamin</a>
                    {' '}
                    i wyrażam zgodę na przetwarzanie przez Edu-Sense SA z siedzibą w Gdańsku, 80-742,
                    ul. Kieturakisa 10/7, moich danych osobowych przekazanych przeze mnie dobrowolnie
                    w formularzu zakupowym Koszyka Sklepu w celu zawarcia i wykonania umowy sprzedaży
                    oraz obsługi posprzedażowej zgodnie z Rozporządzeniem Parlamentu Europejskiego i Rady
                    (UE) 2016/679 w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych
                    osobowych i w sprawie sowbodnego przepływu takich danych z dnia 27 kwietnia 2016 r.
                    (Dz. Urz. UE L 119 z 04.05.2016). Więcej informacji o przetwarzaniu Twoich danych
                    osobowych znajdziesz w par. 11
                    {' '}
                    <a href="/pl/regulamin" rel="noopener noreferrer" target="_blank">Regulaminu</a>
                &nbsp;i w
                    {' '}
                    <a href="/pl/polityka-prywatnosci" rel="noopener noreferrer" target="_blank">
                        Polityce prywatności
                    </a>
                    . *
                </span>
            </Form.Check.Label>
            <WarningMessage iscentered="true" type="invalid">Wymagana akceptacja</WarningMessage>
        </Wrapper>
    );
}
