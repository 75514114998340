import { useContext, useState } from 'react';
import CartItemValueObject from '../valueObject/CartItemValueObject';
import CartContext from '../context/cartContext';

const localStorageCartKey = 'cart';
export const localStorageDiscountKey = 'discount';

export default function useCart() {
    const { setCartItemsCount, cartItemsCount } = useContext(CartContext);

    const getItems = () => {
        const itemsParsed = JSON.parse(localStorage.getItem(localStorageCartKey)) || [];
        return itemsParsed.map((item) => new CartItemValueObject(
            item.id, item.sku, item.name, item.price, item.priceBefore || null, item.image, item.preorderText,
            item.stockText, item.isLargeSize, item.isDigital, item.url, item.contentType, item.isVisible,
            item.isBuyable, item.maxQuantityPerOrder, item.quantity, item.sum, item.offerHash
        ));
    };

    const getTotalPrice = () => {
        const items = getItems().filter(({ isVisible, isBuyable }) => isVisible && isBuyable);
        if (items.length <= 0) return 0;
        return items.reduce((prev, current) => ({ sum: prev.sum + current.sum }), { sum: 0 }).sum;
    };

    const getTotalPriceBefore = () => {
        const items = getItems().filter(({ isVisible, isBuyable }) => isVisible && isBuyable);
        if (items.length <= 0) return 0;
        const getBeforePrice = (item) => (item.priceBefore ? item.priceBefore * item.quantity : item.sum);
        return items.reduce(
            (prev, current) => ({ sum: getBeforePrice(prev) + getBeforePrice(current) }), { sum: 0 },
        ).sum;
    };

    const validateCartItemValueObject = (item) => {
        if (!(item instanceof CartItemValueObject)) {
            throw new Error(`Item must be of type CartItem, not : ${typeof item}`);
        }
    };

    const saveToLocalStorage = (items) => {
        localStorage.setItem(localStorageCartKey, JSON.stringify(items));
        setCartItemsCount(getItems().reduce((prev, current) => prev + current.quantity, 0));
    };

    return {
        saveDiscount: (discount) => discount
            && sessionStorage.setItem(localStorageDiscountKey, JSON.stringify(discount)),
        loadDiscount: () => JSON.parse(sessionStorage.getItem(localStorageDiscountKey)) || null,
        addItem: (item) => {
            validateCartItemValueObject(item);

            const items = getItems();
            const actualItem = items.filter((i) => i.id === item.id)[0];
            if (actualItem) {
                actualItem.increaseQuantity();
                if (actualItem.quantity >= actualItem.maxQuantityPerOrder) {
                    actualItem.setQuantity(actualItem.maxQuantityPerOrder);
                }
            } else {
                items.push(item);
            }

            saveToLocalStorage(items);
        },
        decreaseQuantityOfItem: (item) => {
            validateCartItemValueObject(item);

            const items = getItems();
            const actualItem = items.filter((i) => i.id === item.id)[0];
            if (actualItem) actualItem.decreaseQuantity();
            if (actualItem.quantity <= 0) actualItem.setQuantity(1);

            saveToLocalStorage(items);
        },
        changeQuantityOfItem: (updatedItem) => {
            validateCartItemValueObject(updatedItem);

            const items = getItems();
            items.map((item) => {
                if (item.id === updatedItem.id) {
                    item.setQuantity(updatedItem.quantity);
                    if (item.quantity >= item.maxQuantityPerOrder) item.setQuantity(item.maxQuantityPerOrder);
                }
                return item;
            });

            saveToLocalStorage(items);
        },
        changePriceOfItem: (updatedItem) => {
            validateCartItemValueObject(updatedItem);

            const items = getItems();
            items.map((item) => {
                if (item.id === updatedItem.id) {
                    item.setPrice(updatedItem.price);
                }
                return item;
            });

            saveToLocalStorage(items);
        },
        changePriceBeforeOfItem: (updatedItem) => {
            validateCartItemValueObject(updatedItem);

            const items = getItems();
            items.map((item) => {
                if (item.id === updatedItem.id) {
                    item.setPriceBefore(updatedItem.priceBefore);
                }
                return item;
            });

            saveToLocalStorage(items);
        },
        changeStockTextOfItem: (updatedItem) => {
            validateCartItemValueObject(updatedItem);

            const items = getItems();
            items.map((item) => {
                if (item.id === updatedItem.id) {
                    item.setStockText(updatedItem.stockText);
                }
                return item;
            });

            saveToLocalStorage(items);
        },
        changePreorderTextOfItem: (updatedItem) => {
            validateCartItemValueObject(updatedItem);

            const items = getItems();
            items.map((item) => {
                if (item.id === updatedItem.id) {
                    item.setPreorderText(updatedItem.preorderText);
                }
                return item;
            });

            saveToLocalStorage(items);
        },
        changeMaxQuantityPerOrder: (updatedItem) => {
            validateCartItemValueObject(updatedItem);

            const items = getItems();
            items.map((item) => {
                if (item.id === updatedItem.id) {
                    item.setMaxQuantityPerOrder(updatedItem.maxQuantityPerOrder);
                }
                return item;
            });

            saveToLocalStorage(items);
        },
        changeIsVisibleOfItem: (updatedItem) => {
            validateCartItemValueObject(updatedItem);

            const items = getItems();
            items.map((item) => {
                if (item.id === updatedItem.id) {
                    item.setIsVisible(updatedItem.isVisible);
                }
                return item;
            });

            saveToLocalStorage(items);
        },
        changeIsBuyableOfItem: (updatedItem) => {
            validateCartItemValueObject(updatedItem);

            const items = getItems();
            items.map((item) => {
                if (item.id === updatedItem.id) {
                    item.setIsBuyable(updatedItem.isBuyable);
                }
                return item;
            });

            saveToLocalStorage(items);
        },
        removeItem: (item) => {
            validateCartItemValueObject(item);
            saveToLocalStorage(getItems().filter((i) => i.id !== item.id));
        },
        itemsCount: cartItemsCount,
        useItemsCountState: () => useState(getItems().reduce((prev, current) => prev + current.quantity, 0)),
        getItems,
        getTotalPrice,
        getTotalPriceBefore,
        removeAll: () => {
            localStorage.removeItem(localStorageCartKey);
            sessionStorage.removeItem(localStorageDiscountKey);
            setCartItemsCount(0);
        },
        removeDiscount: () => sessionStorage.removeItem(localStorageDiscountKey),
        isLargeSizeItem: () => getItems().find((item) => item.isLargeSize === true) !== undefined,
        onlyDigitalItems: () => getItems().length > 0
            && getItems().filter((item) => item.isDigital === true).length === getItems().length,
    };
}
