import React from 'react';
import {
    DepartmentHeader, EmployeeCarouselItem, EmployeesCarousel,
    EmployeesContainer, StyledContact,
} from './ContactStyles';
import Employee from './Employee/Employee';

const Contact = ({ deparmentName, employees }) => (
    <StyledContact>
        <DepartmentHeader className={employees.length === 1 && "text-left"}>{deparmentName}</DepartmentHeader>
        <EmployeesContainer numberOfEmployees={employees.length}>
            {employees.map((employee) => (
                <Employee
                    key={employee.name}
                    employee={employee}
                />
            ))}
        </EmployeesContainer>
        <EmployeesCarousel indicators={false}>
            {employees.map((employee) => (
                <EmployeeCarouselItem key={employee.name}>
                    <Employee
                        employee={employee}
                    />
                </EmployeeCarouselItem>
            ))}
        </EmployeesCarousel>
    </StyledContact>
);

export default Contact;
