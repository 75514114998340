import PostOrder from '../../api/Order/PostOrder';
import OrderClient from '../../api/Order/models/OrderClient';
import OrderItem from '../../api/Order/models/OrderItem';
import Order from '../../api/Order/models/Order';
import OrderPayment from '../../api/Order/models/OrderPayment';

export default function SendForm(data) {
    const client = new OrderClient(
        data.firstName,
        data.lastName,
        data.email,
        data.phone,
        data.institution,
        data.address,
        data.postCode,
        data.city,
        data.comment,
    );

    const items = [];
    data.items.map((item) => items.push(new OrderItem(item.id, item.quantity)));

    const payment = new OrderPayment(
        'PLN',
        data.paymentType,
        data.invoiceName,
        data.invoiceAddress,
        data.invoicePostcode,
        data.invoiceCity,
        data.invoiceTaxId,
        data.invoiceSeparateRecipient ? 1 : 2,
        data.invoiceRecipientName,
        data.invoiceRecipientAddress,
        data.invoiceRecipientPostcode,
        data.invoiceRecipientCity,
        data.invoiceRecipientTaxId,
        data.returnUrl,
        data.returnErrorUrl,
    );

    const order = new Order(
        data.incentive,
        data.deliveryType,
        data.deliveryExtra,
        data.deliveryInpostAddress,
        data.deliverySplit,
        items,
        client,
        data.discount,
        payment,
        data.referer,
        data.marketingConsent,
        data.offerHash,
    );

    return PostOrder(order);
}
