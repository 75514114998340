import React, { useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { ClipLoader } from 'react-spinners';
import { getNoAuth } from '../../api/Api';
import { getToken } from '../../api/Auth';
import useCart from '../Cart/hook/useCart';
import {
    DeliveriesContainer,
    RadioColumn,
    Radio,
    SectionHeader,
    InpostMessage,
    InpostAddress,
    InpostMessageContainer,
    StyledModal,
    StyledWarningMessage,
    StyledInpostMessage,
    StyledPrice,
    MessageContainer,
    LoaderContainer,
    DeliveriesColumn,
} from './DeliveriesStyles';

export const DELIVERY_INPOST = 'inpost';

const Deliveries = ({
    data, setDataValue, cartTotalPrice, deliveries, setDeliveries, validated,
}) => {
    const { isLargeSizeItem, onlyDigitalItems, itemsCount } = useCart();
    const [show, setShow] = useState(false);

    useEffect(() => {
        getNoAuth('deliveries').then((deliveries) => setDeliveries(deliveries));
        getToken();
    }, [setDeliveries]);

    const removeDeliveryFromData = () => {
        const deliveryProperties = [
            'deliveryType',
            'deliveryPrice',
            'deliveryExtra',
            'deliveryInpostAddress',
            'deliverySplit',
        ];

        deliveryProperties.forEach((property) => {
            setDataValue(property, undefined);
        });
    };

    const removeInpostExtraFromData = () => {
        const deliveryProperties = [
            'deliveryExtra',
            'deliveryInpostAddress',
        ];

        deliveryProperties.forEach((property) => {
            setDataValue(property, undefined);
        });
    };

    useEffect(() => {
        if (onlyDigitalItems()) {
            removeDeliveryFromData();
        }
        // eslint-disable-next-line
    }, [itemsCount]);

    useEffect(() => {
        if (onlyDigitalItems()) {
            removeDeliveryFromData();
        }

        if ((data.deliveryType === DELIVERY_INPOST && isLargeSizeItem()) || onlyDigitalItems()) {
            removeDeliveryFromData();
        }

        if (!(data.deliveryType === DELIVERY_INPOST)) {
            removeInpostExtraFromData();
        }
        // eslint-disable-next-line
    }, [data.deliveryType]);

    const defaultLocation = {
        latitude: 52.237049,
        longitude: 21.017532,
    };

    const handleLoad = () => {
        // eslint-disable-next-line func-names
        window.easyPackAsyncInit = function () {
            window.easyPack.init({
                defaultLocale: 'pl',
                points: {
                    types: ['parcel_locker_only'],
                },
                map: {
                    initialTypes: ['parcel_locker_only'],
                    defaultLocation,
                },
            });
        };

        window.easyPack.mapWidget('easypack-map', ({ name, address }) => {
            setDataValue('deliveryExtra', name);
            setDataValue('deliveryInpostAddress', `${address.line1}, ${address.line2}`);
            setShow(false);
        });
    };

    const handleClose = () => setShow(false);

    const handleModalOpen = async (deliveryType) => {
        if (!isLargeSizeItem() && !onlyDigitalItems() && deliveryType === DELIVERY_INPOST) {
            await handleLoad();
            await setShow(true);
            await window.easyPackAsyncInit();
        }
    };

    const handleDeliverySelect = (e, delivery, deliveryPrice) => {
        if (delivery.type === DELIVERY_INPOST) {
            handleModalOpen(delivery.type);
        }
        setDataValue('deliveryType', e.currentTarget.checked && delivery.type);
        setDataValue('deliveryPrice', e.currentTarget.checked && deliveryPrice);
    };

    return (
        <>
            <DeliveriesContainer>
                <SectionHeader>Sposób wysyłki:</SectionHeader>
                {deliveries.length > 0
                    ? deliveries.map((delivery, key) => {
                        const isFreeDelivery = delivery.price_threshold_for_free <= cartTotalPrice;
                        const deliveryPrice = isFreeDelivery ? 0 : delivery.price;

                        return (
                            <DeliveriesColumn key={key}>
                                <Radio id={`delivery${key}`}>
                                    <Form.Check.Input
                                        disabled={(delivery.type === DELIVERY_INPOST
                                                && isLargeSizeItem()) || onlyDigitalItems()}
                                        name="delivery"
                                        checked={data.deliveryType === delivery.type}
                                        onChange={(e) => handleDeliverySelect(e, delivery, deliveryPrice)}
                                        type="radio"
                                        required
                                    />
                                    <Form.Check.Label>
                                        <RadioColumn className="fit">
                                            <span className="placeholder" />
                                        </RadioColumn>
                                        <RadioColumn className="pl-5">
                                            <RadioColumn className="header">
                                                {delivery.name}
                                            </RadioColumn>
                                            <RadioColumn className="price">
                                                <StyledPrice
                                                    price={onlyDigitalItems() ? 0 : parseFloat(deliveryPrice)}
                                                />
                                            </RadioColumn>
                                            {!onlyDigitalItems() && !isFreeDelivery
                                            && delivery.price_threshold_for_free > 0
                                            && (
                                                <RadioColumn className="sub">
                                                    Darmowa dostawa już od&nbsp;
                                                    {parseInt(delivery.price_threshold_for_free, 10)}
                                                &nbsp;zł.
                                                </RadioColumn>
                                            )}
                                            {onlyDigitalItems()
                                            && (
                                                <RadioColumn className="sub">
                                                    W zamówieniu znajdują się tylko produkty,
                                                    które nie wymagają wysyłki.
                                                </RadioColumn>
                                            )}
                                        </RadioColumn>
                                    </Form.Check.Label>
                                    <StyledWarningMessage type="invalid">Wybierz sposób wysyłki</StyledWarningMessage>
                                </Radio>
                            </DeliveriesColumn>
                        );
                    })
                    : (
                        <LoaderContainer>
                            <ClipLoader
                                size={100}
                                color="#00437E"
                                loading
                            />
                        </LoaderContainer>
                    )}
                <InpostMessageContainer onClick={() => handleModalOpen(data.deliveryType)}>
                    {data.deliveryType === DELIVERY_INPOST && (
                        !(data.deliveryExtra && data.deliveryInpostAddress)
                            ? (
                                <InpostMessage validated={validated}>
                                    Wybierz paczkomat
                                </InpostMessage>
                            )
                            : (
                                <MessageContainer>
                                    <InpostMessage>
                                        Wybrany paczkomat:
                                        {' '}
                                        <InpostAddress>
                                            {data.deliveryExtra}
                                        </InpostAddress>
                                    </InpostMessage>
                                    <InpostMessage>
                                        <InpostAddress>
                                            {data.deliveryInpostAddress.split(',')[0]}
                                            ,
                                            <br />
                                            {data.deliveryInpostAddress.split(',')[1]}
                                        </InpostAddress>
                                    </InpostMessage>
                                    <StyledInpostMessage>
                                        Zmień paczkomat
                                    </StyledInpostMessage>
                                </MessageContainer>
                            )
                    )}
                </InpostMessageContainer>
            </DeliveriesContainer>

            <StyledModal show={show} onHide={handleClose}>
                <Modal.Header closeButton />
                <Modal.Body className="modalBody">
                    <div id="easypack-map" />
                </Modal.Body>
            </StyledModal>
        </>
    );
};

export default Deliveries;
