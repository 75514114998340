import styled from 'styled-components';
import { Button, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Container from '../Container/Container';

export const FooterRow = styled(Row)`
    display: grid;
    grid-template-columns: 1fr auto 1fr;

    @media(max-width: 991px) {
        grid-template-columns: 1fr;
    }

    @media(max-width: 350px) {
        padding: 0;
    }
`;

export const InfoText = styled.h6`
    font-weight: bold;
    color: #00437e;
    margin: 30px 0;
`;

export const FooterContainer = styled.footer`
    text-align: left;
    background-color: #00AFFF;
    background-position-y: bottom;
    background-repeat: repeat-x;
    margin: 15rem 0 0 0;
    padding: 50px 0 20px;
    font-weight: 700 !important;
    font-size: 1rem;
    color: #fff;
    max-width: 100%;
    position: relative;

    @media (max-width: 1350px) {
        padding: 50px 24px;
    }
`;

export const SocialLink = styled.a`
    display: block;
    width: 75px;
    height: 75px;
    padding: 20px 20px 20px 0;
    margin-left: -12px;

    &:hover {
        filter: brightness(0) saturate(100%) invert(100%) sepia(6%)
        saturate(0%) hue-rotate(76deg) brightness(107%) contrast(101%);
    }
`;

export const SocialImage = styled.img`
    display: block;
    width: 100%;
    height: 100%;
`;

export const IconsContainer = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
`;

export const FooterContactButton = styled(Button)`
    padding: 24px;
    color: #fff;
    font-size: 1.3em;
    font-weight: 700;
    background-color: #00437E;
    margin: 0.5rem 0.5rem 0 15%;
    outline: none;
    width: 65%;
    border-radius: 5px;
  
    &:focus {
        box-shadow: none;
    }

    &:hover{
        color: #00437E;
        background-color: white;
        border-color: white;
    }

    @media(max-width: 1280px) {
        margin-left: 0;
    }

    @media(max-width: 991px) {
        width: 100%;
    }

    @media(max-width: 350px) {
        width: 95%;
    }
`;

export const StyledLink = styled(Link)`
    display: block;
    color: #00437e;
    font-style: italic;
    text-transform: uppercase;
    font-size: 16px;

    &:hover {
        color: white;
    }

    @media (max-width: 992px) {
        line-height: 1;
        padding: 2px 0;
    }
`;

export const FooterAnchor = styled.div`
    position: absolute;
    top: -200px;
`;

export const ContactInfo = styled.div`
    padding: 0;
    margin-left: 15%;

    @media(max-width: 1280px) {
      margin-left: 0;
    }
`;

export const StyledContainer = styled(Container)`
    width: 100% !important;
    background: none;
`;

export const SmallText = styled.p`
    font-size: 11px;
    font-family: 'Roboto', sans-serif;
    text-align: justify;
    font-weight: 400;
    line-height: 1.2;
`;

export const DepartmentsWrapper = styled.section`
    display: grid;
    grid-template-columns: 2fr 2fr;
    grid-gap: 50px;
    
    @media (max-width: 992px) {
        grid-template-columns: 1fr; 
    }
`;

export const LinksColumn = styled.div`
    padding: 0 8px 0 0;

    @media (max-width: 575px) {
        margin: 16px 0;
        padding: 0;
    }
`;

export const AddressColumn = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    font-size: 16px;
    line-height: 1.2;
        
    @media (max-width: 575px) {
        margin: 16px 0;
    }
`;

export const InvoiceColumn = styled(AddressColumn)`
    @media (max-width: 575px) {
        margin: 16px 0;
    }
`;

export const Wrapper = styled.section`
    display: flex;
    justify-content: space-between;

    @media (max-width: 992px) {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
    }

    @media (max-width: 575px) {
        grid-template-columns: repeat(2, 1fr);
    }
`;

export const ContactColumn = styled.div`
    @media (max-width: 992px) {
        grid-column: 1 / span 4;
        display: flex;
        justify-content: space-evenly;
        margin: 16px 0;
    }

    @media (max-width: 575px) {
        display: block;
        grid-column: 1 / span 2;

    }
`;

export const EmailLink = styled.a`
    display: block;
    color: white;
    border: 2px solid;
    padding: 18px 24px;
    font-size: 20px;
    text-align: center;

    &:hover {
        background-color: white;
        color: #00AFFF;
    }
`;

export const PhoneLink = styled.a`
    display: block;
    color: white;
    padding: 24px 24px 0 24px;
    margin-bottom: 20px;
    font-size: 20px;
    text-align: center;

    &:hover {
        color: #fff;
        text-decoration: underline;
    }
`;

export const PatronImage = styled.img`
    width: 100%;
    max-width: 700px;
    height: auto;
`;

export const ImageContainer = styled.div`
    display: flex;
    justify-content: left;
`;

export const TextContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
    margin-top: 20px;
    
    @media (max-width: 575px) {
        grid-template-columns: 1fr;
        grid-gap: 0;
    }
`;

export const RightsReserved = styled.h4`
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    font-family: 'Roboto';
    margin-top: 20px;
`;
