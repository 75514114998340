import React, { useState, useEffect, useContext } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import ProductDescription from '../../ProductDescription/ProductDescription';
import Container from '../../Container/Container';
import { getNoAuth } from '../../../api/Api';
import EmptyProductDescription from '../../ProductDescription/EmptyProductDescription';
import AppContext from '../../../context/app-context';
import MetaTags from '../../MetaTags/MetaTags';
import escapeDiacritics from '../../../tools/escapeDiacritics';

export default function AktywnaTablicaProduct({ match }) {
    const productSlug = match.params.product;
    const appContext = useContext(AppContext);
    const language = appContext.lang;
    const [product, setProduct] = useState(null);
    const getTranslation = () => product.translations.filter((t) => t.language === language)[0];

    useEffect(() => {
        getNoAuth(`products/slug/${productSlug}`).then((response) => {
            if (typeof response === 'object' && response.id !== undefined) {
                setProduct(response);
            } else {
                window.location = '/pl/aktywna-tablica';
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productSlug]);

    useEffect(() => window.fbq('track', 'PageView'), []);

    useEffect(() => {
        if (product) {
            const translation = product.translations.filter((t) => t.language === language)[0];
            window.fbq('track', 'ViewContent', {
                content_name: escapeDiacritics(translation.name),
                content_type: 'product',
                content_ids: [product.sku ?? product.id],
                value: product.price,
                currency: 'PLN',
            });
        }
    }, [product, language]);

    return (
        <Container>
            {product && (
                <MetaTags
                    title={`${getTranslation().name} - EduSense`}
                />
            )}
            <Switch>
                <Redirect from="/aktywna-tablica/:category/:slug" to="/aktywna-tablica:slug" />
            </Switch>

            {product === null
                ? <EmptyProductDescription />
                : <ProductDescription product={product} language={language} />}
        </Container>
    );
}
