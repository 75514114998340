import React from 'react';
import { useCookies } from 'react-cookie';
import { Container, StyledText } from './CookieNotificationStyles';
import {useLocation} from "react-router-dom";

export default function CookieNotification({ className }) {
    const [cookies, setCookie] = useCookies(['cookieinfo']);
    const location = useLocation();
    if (cookies.cookieinfo || location.pathname.startsWith('/oferta/')) {
        return <div />;
    }

    return (
        <Container type="cookie" className={`${className || ''} cookie`}>
            <StyledText>
                <h6>
                    Na stronie internetowej
                    {' '}
                    <br />
                    {' '}
                    wykorzystujemy pliki cookies.
                </h6>
                <div style={{marginRight: 80}}>
                    <p>
                        {' '}
                        Jeżeli nie wyrażasz zgody na wykorzystywanie plików cookies, możesz w każdej chwili zablokować je,
                        korzystając z ustawień swojej przeglądarki internetowej. Więcej informacji na temat plików cookies
                        oraz przetwarzania danych osobowych znajdziesz w Polityce prywatności
                    </p>
                    <i onClick={() => setCookie('cookieinfo', true)} className="fa fa-times-circle" aria-hidden="true" />
                </div>
            </StyledText>
        </Container>
    );
}
