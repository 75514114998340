import React from 'react';
import {
    PriceMainSection, StyledPriceMain, PriceMainCurrencyContainer, PriceMainCurrency, Description,
} from './PriceMainStyles';

const PriceMain = ({
    className, price, isOld, savings, description = ''
}) => {
    return (
        <PriceMainSection className={className} isOld={isOld} savings={savings}>
            <div className="hidden-price" style={{display: 'none'}}>
                {new Intl.NumberFormat('pl-PL', { style: 'currency', currency: 'PLN' }).format(price)}
            </div>
            {description && <Description>{description} :</Description>}
            <StyledPriceMain savings={savings}>
                {Math.floor(price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\u00a0')}
            </StyledPriceMain>
            <PriceMainCurrencyContainer savings={savings}>
                <PriceMainCurrency>{(+price % 1).toFixed(2).toString().slice(-2)}</PriceMainCurrency>
                <PriceMainCurrency>zł</PriceMainCurrency>
            </PriceMainCurrencyContainer>
        </PriceMainSection>
    );
};

export default PriceMain;
