import React, {useContext, useEffect} from 'react';
import {WarrantyWrapper} from './WarrantyStyles';
import AppContext from "../../../context/app-context";

export default function Warranty() {
    const appContext = useContext(AppContext);
    const {setGoogleData} = appContext;
    useEffect(() => {
        setGoogleData({'ecomm_pagetype': 'other'})
    }, [setGoogleData]);
    useEffect(() => window.fbq('track', 'PageView'), []);

    return (
        <WarrantyWrapper className="font-dark-blue">
            <div className="h1 font-weight-bold mt-5 mb-4 ml-4">GWARANCJA DYSTRYBUTORA</div>
            <ol>
                <li>Sklep internetowy EDUSENSE jest prowadzony pod adresem <a
                    href="https://edu-sense.com/pl/koszyk">edu-sense.com/pl/koszyk</a> przez. Sklep EDUSENSE dystrybuuje
                    produkty związane z nauką programowania dla dzieci i młodzieży, w szczególności roboty edukacyjne
                    ozoboti związane z nimi materiały dydaktyczne i akcesoria.
                </li>
                <li>Produkty są dystrybuowane na terenie Polski.</li>
                <li>EduSense Sp.z o.o. gwarantuje, że Produkty są wolne od wad materiałowych i wykonawczych i udziela na
                    Produkty:
                    <ul>
                        <li>24 miesięcznej gwarancji dla zakupów dokonanych przez osoby fizyczne, na użytek własny</li>
                        <li>24 miesięcznej gwarancji dla zakupów dokonanych przez placówki oświatowe, na użytek zajęć edukacyjnych</li>
                        <li>12 miesięcznej gwarancji dla zakupów dokonanych przez osoby prawne, licząc od daty
                            otrzymania Produktu („Okres gwarancji”)
                        </li>
                    </ul></li>
                <li>Jeżeli w Okresie gwarancji Dystrybutor stwierdzi, że Produkt jest wadliwy i objęty niniejszą
                    Gwarancją, Dystrybutor może, wyłącznie według własnego uznania:
                    <ul>
                        <li>bezpłatnie usunąć wadę</li>
                        <li>wymienić reklamowany Produkt na nowy, o ile jest on wciąż produkowany lub wymienić Produkt
                            na nowy o tej samej lub wyższej wartości
                        </li>
                        <li>zamienić Produkt na produkt odnowiony, pod warunkiem, że produkt odnowiony odpowiada
                            oryginalnej specyfikacji Produktu
                        </li>
                    </ul></li>
                <li>Produkty lub części zamienne będą objęte Gwarancją przez pozostałą część oryginalnego Okresu
                    gwarancji lub przez okres 1 roku od daty naprawy lub wymiany, w zależności od tego, który z nich
                    będzie dłuższy.
                </li>
                <li>Niniejsza Gwarancja nie obejmuje strat lub uszkodzeń Produktu na skutek:
                    <ul>
                        <li>wypadku, nadużyć, nieprawidłowego wykorzystania lub jakichkolwiek nieautoryzowanych napraw,
                            modyfikacji, czy demontażu;
                        </li>
                        <li>nieprawidłowego użytkowania lub przechowywania, nieprzestrzegania instrukcji obsługi lub
                            podłączenia do źródła prądu o nieodpowiednim napięciu;
                        </li>
                        <li>zgubienia oryginalnych części Produktu;</li>
                        <li>normalnego zużycia;</li>
                        <li>szkodliwego oprogramowania lub innych działań pozostających poza zakresem kontroli ze strony
                            Dystrybutora lub Producenta
                        </li>
                    </ul></li>
                <li>Gwarancja nie wyłącza, nie ogranicza ani nie zawiesza uprawnień kupującego wynikających z przepisów
                    o rękojmi za wady rzeczy sprzedanej.
                </li>
                <li>Gwarancja jest niezbywalna, ograniczona do pierwszego kupującego i ograniczona do państwa, w którym
                    dokonano zakupu Produktów.
                </li>
                <li>EduSense Sp.z o.o. w żadnym wypadku nie ponosi odpowiedzialności za poniesione przez klienta szkody
                    celowe, przypadkowe lub następcze wynikające z naruszenia gwarancji, naruszenia umowy, zaniedbania
                    lub odpowiedzialności na zasadzie ryzyka. Tego rodzaju szkody mogą obejmować: utratę zysków,
                    oszczędności lub przychodów, niemożność użytkowania produktu lub powiązanych z nim akcesoriów,
                    niemożność użytkowania sprzętu lub oprogramowania, koszty urządzenia zastępczego, roszczenia osób
                    trzecich, szkody majątkowe i/lub utratę wartości przedsiębiorstwa.
                </li>
            </ol>
            <div className="h2 font-weight-bold">Instrukcja korzystania z serwisu gwarancyjnego:</div>
            <p>Skontaktuj się ze sklepem, w którym dokonałeś zakupu celem stwierdzenia, czy Twój Produkt podlega
                wymianie gwarancyjnej. Jeśli będzie się kwalifikował, zwróć Produkt w oryginalnym opakowaniu lub innym
                opakowaniu zapewniającym ten sam stopień zabezpieczenia Produktu. Przed przyjęciem do usługi
                gwarancyjnej będziemy też wymagali okazania dowodu zakupu (paragon/faktura). Pamiętaj, aby wykonać kopię
                zapasową danych, oprogramowania lub innych materiałów, które są przechowywane są w Twoim Produkcie.</p>
        </WarrantyWrapper>
    );
}
