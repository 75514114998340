import styled from 'styled-components';
import { Text, Wrapper } from '../Notification/NotificationStyles';

export const Container = styled(Wrapper)`
    background-color: #00437E;
    height: auto;
    padding: 20px;
`;

export const StyledText = styled(Text)`
    font-family: 'Roboto', sans-serif;
    display: grid;
    max-width: 1280px;
    grid-template-columns: 40% 40%;
    align-items: center;
    grid-gap: 20px;
    
    & div {
        display: flex;
    }

    @media(max-width: 575px) {
        grid-template-columns: 100%;
        grid-gap: 10px;
    }
`;
