import { useContext } from 'react';
import AppContext from '../../context/app-context';

const useGetProductUrl = () => {
    const appContext = useContext(AppContext);

    return (subpage, slug, isForPreschool, isForSchool) => {
        switch (subpage) {
        case 1:
            return `${appContext.routeMap.courses.path[appContext.lang]}/${slug}`;
        default:
            if (isForPreschool) {
                return `${appContext.routeMap.products_for_preschool.path[appContext.lang]}/${slug}`;
            }
            if (isForSchool) {
                return `${appContext.routeMap.products_for_school.path[appContext.lang]}/${slug}`;
            }
            return `${appContext.routeMap.products.path[appContext.lang]}/${slug}`;
        }
    };
};

export default useGetProductUrl;
