export default function CartItemValueObject(
    id,
    sku,
    name,
    price,
    priceBefore,
    image,
    preorderText,
    stockText,
    isLargeSize,
    isDigital,
    url,
    contentType,
    isVisible,
    isBuyable,
    maxQuantityPerOrder = null,
    quantity = 1,
    sum = price * quantity,
    offerHash = null,
) {
    this.id = id;
    this.sku = sku;
    this.name = name;
    this.price = price;
    this.priceBefore = priceBefore;
    this.image = image;
    this.preorderText = preorderText;
    this.stockText = stockText;
    this.quantity = quantity;
    this.sum = sum;
    this.isLargeSize = isLargeSize;
    this.isDigital = isDigital;
    this.url = url;
    this.contentType = contentType;
    this.isVisible = isVisible;
    this.isBuyable = isBuyable;
    this.maxQuantityPerOrder = maxQuantityPerOrder;
    this.offerHash = offerHash;

    this.increaseQuantity = () => {
        this.quantity += 1;
        this.countSum();
    };

    this.decreaseQuantity = () => {
        this.quantity -= 1;
        this.countSum();
    };

    this.setQuantity = (quantity) => {
        this.quantity = quantity;
        this.countSum();
    };

    this.setPrice = (price) => {
        this.price = price;
        this.countSum();
    };

    this.setPriceBefore = (priceBefore) => {
        this.priceBefore = priceBefore;
    };

    this.setStockText = (stockText) => {
        this.stockText = stockText;
    };

    this.setPreorderText = (preorderText) => {
        this.preorderText = preorderText;
    };

    this.countSum = () => {
        this.sum = this.price * this.quantity;
    };

    this.setIsVisible = (isVisible) => {
        this.isVisible = isVisible;
    };

    this.setIsBuyable = (isBuyable) => {
        this.isBuyable = isBuyable;
    };

    this.setMaxQuantityPerOrder = (maxQuantityPerOrder) => {
        this.maxQuantityPerOrder = maxQuantityPerOrder;
    };
}
