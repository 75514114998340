import styled from 'styled-components';
import ReactTransitionGroup from 'react-addons-css-transition-group';

export const TransitionGroup = styled(ReactTransitionGroup)`
    .notify-appear {
        width: 0;
        height: 0;
    }
    
    .notify-appear.notify-appear-active {
        height: 100px;
        width: 100%;
        transition: width .3s ease-in, height .3s ease-in;
    }
`;

export const Wrapper = styled.div`
    position: fixed;
    height: 100px;
    width: 100%;
    bottom: 0;
    background: ${(props) => (props.type === 'error' ? 'red' : '#65BA00')};
    z-index: ${(props) => (props.type === 'cookie' ? 5000 : 5001)};
    display: flex;
    flex-direction: column;
    
    @media(max-width: 575px) {
        height: auto;
    }

    &.cookie {
        background: #00437E;
        color: white;
        
        @media print {
            display: none;
        }
    }
    &.cookie i {
        font-size: 30px;
        cursor: pointer;
    }
    &.cookie h6 {
        font-size: 13px;
        font-weight: bold;
        color: white;
        margin-bottom: 7px;
        text-align: right;
        margin: 0;
        
        @media(max-width: 575px) {
            text-align: left;
        }
    }
    &.cookie p {
        font-size: 9px;
        color: white;
        line-height: 1.3;
        text-align: left;
        margin: 0;

        @media(max-width: 575px) {
            text-align: justify;
        }
    }
`;

export const Text = styled.div`
    color: white;
    font-size: 1.2em;
    margin: auto;
`;
