import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import Container from "../../Container/Container";
import MetaTags from "../../MetaTags/MetaTags";
import OfferCatalogProduct from "../../OfferCatalogProduct/OfferCatalogProduct";
import {get} from "../../../api/Api";
import {Column, Description, Row} from "../../ProductDescription/ProductDescriptionStyles";
import {useParams} from "react-router-dom";
import useNotification from "../../NotificationContainer/hook/useNotification";

const Line = styled.div`
    height: 5px;
    background-color: #00aeef;
    margin: 10px;
    width: 100%;
    
    @media print {
        & {
            -webkit-print-color-adjust: exact !important;   /* Chrome, Safari, Edge */
            color-adjust: exact !important;                 /*Firefox*/
        }
    }
`;

const Footer = styled.div`
    margin-bottom: 50px;
    color: #00aeef;
`;

const Header = styled.div`
    margin-top: 10px;
    
    @media print {
        & {
            margin-top: 0;
        }
    }
    
    & .left {
        margin-top: 40px;
        width: 70%;
        color: #00aeef;
        font-weight: bold;
        display: inline-block;
    }
    
    & .right {
        width: 30%;
        display: inline-block;
    }
    
    & img {
        max-width: 150px;
    }
    
    & a {
        color: #00aeef;
    }
`;

const ContainerPrint = styled.div`
    ${Row} {
        margin: 50px 10px 30px;
    }

    @media print {
        & .catalog-product-container {
            margin-top: 0;  
        }
    
        p, div {
            color: #000;
            line-height: 1.3;
        }
    
        .page-break {
            break-after: page;
        }
    
        ${Row} {
            margin: 0;
            display: block;
            background-color: white;
        }
    
        ${Column} ${Description},
        #more-product-description {
            display: none;
        }
    
        h1 {
            font-size: 24pt;
        }
            
        h2 {
            font-size: 14pt;
            margin-top: 25px;
        }
            
        aside h2 {
            font-size: 18pt;
        }
        
        img {
            margin-top: 80px;
            max-width: 300px;
            border: 0;
        }
        
        img.logo {
            max-width: 100px;
            margin-top: 0;
            border: 0;
        }
        
        ul {list-style: none}
        
        @page {
            margin: 2cm;
        }
        
        a {
            color: #000;
        }
        
        p a {
            word-wrap: break-word;
        }
        
        p a:after {
            content: " (" attr(href) ")";
            font-size: 80%;
        }
    }
`;

function ProductsList({products}) {
    return products.map((product, index) => <OfferCatalogProduct
        key={index} product={product}
        className={'bg-none catalog-product-container ' + (index < (products.length - 1) && 'page-break')}/>);
}

export default function OfferCatalog() {
    const {number} = useParams();
    const [offer, setOffer] = useState();
    const {notify} = useNotification();
    const [products, setProducts] = useState([]);

    useEffect(() => {
        get('offers/' + number).then((data) => {
            if (data.success !== undefined && data.success === false) {
                notify(data.message, 'error');
            } else {
                setOffer(data);
            }
        });
        // eslint-disable-next-line
    }, [number]);

    useEffect(() => {
        if (!offer) {
            return;
        }

        const products = [];
        const uniqueUsedItemIdsTemp = [];
        offer.items.forEach((item) => {
            if (item.is_active) {
                if (!uniqueUsedItemIdsTemp.find((i) => i === item.product.id)) {
                    products.push(item.product);
                    uniqueUsedItemIdsTemp.push(item.product.id);
                }
            }
        })

        setProducts(products);
    }, [offer]);

    return (
        <Container className="bg-none">
            <MetaTags title="Produkty EduSense"/>
            <ContainerPrint>
                <Header>
                    <div className="left">
                        <Line/>
                        <div>
                            |&nbsp;&nbsp;&nbsp;EduSense&nbsp;SA&nbsp;&nbsp;&nbsp;|
                            &nbsp;&nbsp;&nbsp;
                            <a href="https://edu-sense.com/pl">www.edu-sense.pl</a>
                            &nbsp;&nbsp;&nbsp;|
                        </div>
                        <Line/>
                    </div>
                    <div className="right">
                        <img className="logo" src={`${process.env.REACT_APP_CDN_URL}edu_logo.png`} alt=""/>
                    </div>
                </Header>
                <ProductsList products={products}/>
                <Footer>
                    <Line/>
                    <div>EduSense SA, ul. Kieturakisa 10/7, 80-742 Gdańsk, NIP: 946 265 72 65</div>
                    <Line/>
                </Footer>
            </ContainerPrint>
        </Container>
    );
}
