import React, { useLayoutEffect, useState } from 'react';
import latinize from 'latinize';
import Card from './Card/Card';
import { CategoryAnchor, RowStyled } from './ProductsListStyles';
import Container from '../Container/Container';
import RowTitle from '../RowTitle/RowTitle';
import escapeDiacritics from '../../tools/escapeDiacritics';
import useGetProductUrl from './useGetProductUrl';

export default function ProductsList({
    categories,
    language,
    subpage = 0,
    isForPreschool = false,
    isForSchool = false,
}) {
    const [gtagSend, setGtagSend] = useState(false);
    const getProductUrl = useGetProductUrl();

    const renderCategories = () => {
        const gtagItems = [];
        const renderedCategories = categories.map((category) => {
            if (category.onlyNav === true) return null;

            const translations = category.translations.filter((i) => i.language === language);
            if (translations.length === 0) return null;

            let title = '';
            if (translations.length > 0) {
                title = translations[0].long_name !== undefined ? translations[0].long_name : translations[0].name;
            }

            if (title.toLowerCase() === 'zestawy') {
                if (isForPreschool) {
                    title = 'Zestawy przedszkolne';
                }

                if (isForSchool) {
                    title = 'Zestawy szkolne';
                }
            }

            const renderProducts = () => category.products.map((item) => {
                if (item.translations.length <= 0) {
                    return false;
                }

                if (isForPreschool) {
                    if (!item.is_for_preschool) {
                        return false;
                    }
                }

                if (isForSchool) {
                    if (!item.is_for_school) {
                        return false;
                    }
                }

                const itemContent = item.translations.filter((item) => item.language === language)[0];
                gtagItems.push({
                    id: item.sku,
                    name: escapeDiacritics(itemContent.name),
                    price: item.price,
                    category: escapeDiacritics(title),
                    quantity: 1,
                });

                return (
                    <Card
                        key={item.id}
                        product={item}
                        language={language}
                        isBlackWeek={category.color === '#000000'}
                        isChristmas={escapeDiacritics(title).toLowerCase().startsWith('swiateczna promocja')}
                        productUrl={getProductUrl(subpage || category.subpage, item.slug, isForPreschool, isForSchool)}
                    />
                );
            });
            return (
                <React.Fragment key={category.id}>
                    <CategoryAnchor
                        subpage={subpage}
                        id={category.translations
                            .filter((i) => i.language === language)
                            .map((translation) => latinize(translation.name.replace(/\s/g, '-').toLowerCase()))}
                    />
                    <RowStyled>
                        <RowTitle color={category.color} textColor={category.text_color} text={title} />
                        <RowStyled className="d-flex flex-wrap flex-col w-100">
                            {renderProducts()}
                        </RowStyled>
                    </RowStyled>
                </React.Fragment>
            );
        });

        if (!gtagSend) {
            window.gtag('event', 'view_item_list', {
                items: gtagItems.map((item, key) => {
                    item.list_position = key + 1;
                    return item;
                }),
            });
            setGtagSend(true);
        }

        return renderedCategories;
    };

    useLayoutEffect(() => {
        const categoryHash = document.location.hash;
        const forbiddenChars = /[ `!@$%^&*()_+=[\]{};':"\\|,.<>/?~]/;
        if (categories.length > 0 && categoryHash && !forbiddenChars.test(categoryHash)) {
            const element = document.querySelector(categoryHash);
            if (!element) return;
            window.scrollTo(0, element.offsetTop);
        }
    }, [categories]);

    return (
        <Container className="bg-none">
            {renderCategories()}
        </Container>
    );
}
