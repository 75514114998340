import {useContext, useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import { ANY_PAGE_THAT_WILL_BE_REPLACED_IN_CONTENT } from '../anyPage';
import AppContext from '../context/app-context';

export default withRouter(({children, location}) => {
    const appContext = useContext(AppContext);
    useEffect(() => {
        const page = children.props.children.find((e) => {
            if (e.props && e.props.path) {
                if ((e.key === 'offer' && location.pathname.startsWith('/oferta'))
                    || (e.key === 'cart' && location.pathname.startsWith('/koszyk'))) {
                    return true;
                }

                if (location.pathname.startsWith(
                    e.props.path
                        .replace(`:${ANY_PAGE_THAT_WILL_BE_REPLACED_IN_CONTENT}`, '.+')
                        .replace(':product', ''),
                )) {
                    return true;
                }
            }

            return false;
        });

        appContext.setRoutePage(page ? page.props.path : null);
        appContext.setPage(page ? page.key : null);
    }, [location, children, appContext]);

    return children;
});
