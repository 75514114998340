import React from "react";
import {
    Column,
    Description,
    Header,
    Image,
    LeftColumn,
    Row
} from "../ProductDescription/ProductDescriptionStyles";
import Container from "../Container/Container";
import ReactMarkdown from "react-markdown";

export default function OfferCatalogProduct({product, className}) {
    const language = 'pl';

    const renderProduct = () => {
        if (product.translations === undefined || product.translations.length <= 0) return;
        const translation = product.translations.filter((t) => t.language === language)[0];

        // eslint-disable-next-line consistent-return
        return (
            <>
                <Row>
                    <LeftColumn className="d-none d-lg-flex">
                        {product.main_image
                            && (
                                <Image
                                    src={`${process.env.REACT_APP_CDN_URL + product.main_image.path}`}
                                    alt="product image"
                                />
                            )}
                    </LeftColumn>
                    <Column>
                        <Header>{translation.name}</Header>
                        <div className="d-block d-lg-none">
                            {product.main_image
                                && (
                                    <Image
                                        src={`${process.env.REACT_APP_CDN_URL + product.main_image.path}`}
                                        alt="product image"
                                    />
                                )}
                        </div>
                        <Description>
                            {translation.short_description}
                        </Description>
                    </Column>
                </Row>
                <div id="more-product-description" className="p-4" />
                <Row className="text-left bg-white">
                    <Description>
                        <ReactMarkdown source={translation.description} skipHtml={true}/>
                    </Description>
                </Row>
            </>
        );
    };

    return (
        <Container className={className}>
            {renderProduct()}
        </Container>
    );
}
