import React, {useContext, useEffect} from 'react';
import {PrivacyWrapper} from './PrivacyStyles';
import AppContext from "../../../context/app-context";

export default function Privacy() {
    const appContext = useContext(AppContext);
    const {setGoogleData} = appContext;
    useEffect(() => {
        setGoogleData({'ecomm_pagetype': 'other'})
    }, [setGoogleData]);
    useEffect(() => window.fbq('track', 'PageView'), []);

    return (
        <PrivacyWrapper className="font-dark-blue">
            <h1 className="font-weight-bold mt-5 mb-3">Polityka prywatności</h1>
            <h2 className="font-weight-bold">CZYM SĄ DANE OSOBOWE I CO OZNACZA ICH PRZETWARZANIE ORAZ KTO JEST ICH
                ADMINISTRATOREM</h2>
            <ol>
                <li>Dane osobowe oznaczają informacje o zidentyfikowanej lub możliwej do zidentyfikowania osobie
                    fizycznej. Przetwarzaniem danych osobowych jest w zasadzie każda czynność na danych osobowych,
                    niezależnie od tego, czy jest dokonywana w sposób zautomatyzowany czy nie, np. zbieranie,
                    przechowywanie, utrwalanie, porządkowanie, modyfikowanie, przeglądanie, wykorzystywanie,
                    udostępnianie, ograniczanie, usuwanie lub niszczenie.
                </li>
                <li>Zbierane przez EduSense SA dane osobowe przetwarzane są w różnych celach, przy czym w
                    zależności od celu, zastosowanie mogą mieć różne sposoby zbierania, podstawy prawne przetwarzania,
                    wykorzystywania, ujawniania oraz okresy przechowywania.
                </li>
                <li>Administratorem danych osobowych jest EduSense SA z siedzibą w Gdańsku, ul. Prof. Z. Kieturakisa
                    10/7, 80-742 Gdańsk, NIP: 9462657265, REGON: 363648719: wpisaną do rejestru przedsiębiorców
                    Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy w Gdańsku, VII Wydział Gospodarczy
                    Krajowego Rejestru Sądowego pod numerem KRS: 0000794442, kapitał zakładowy 1.218.000,00 zł, numer
                    tel. przedsiębiorstwa +48 585 055 914, e-mail: kontakt@edu-sense.pl.
                </li>
            </ol>
            <h3 className="font-weight-bold">KIEDY NINIEJSZA POLITYKA PRYWATNOŚCI MA ZASTOSOWANIE?</h3>
            <ol>
                <li>Ma ona zastosowanie do przypadków, w których Administrator danych osobowych przetwarza je bez
                    względu na to czy przetwarzane dane osobowe pozyskano bezpośrednio od osoby, której dane dotyczą,
                    czy też dane osobowe zostały pozyskane z innych źródeł.
                </li>
            </ol>
            <h3 className="font-weight-bold">W OPARCIU O JAKIE PODSTAWY PRAWNE I JAKIEGO TYPU DANE OSOBOWE BĘDĄ
                PRZETWARZANE PRZEZ ADMINISTRATORA?</h3>
            <ol>
                <li>Osoby fizyczne odwiedzające stronę internetową lub korzystające z usług świadczonych przez
                    Administratora drogą elektroniczną mają kontrolę nad danymi osobowymi, które podają
                    Administratorowi. Administrator ogranicza zbieranie i wykorzystywanie informacji o ich użytkownikach
                    do niezbędnego minimum, wymaganego do świadczenia na ich rzecz usług na pożądanym poziomie,
                    stosownie do art. 18 ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną (tekst
                    jedn. Dz. U. z 2017 r. poz. 1219 ze zm.).
                </li>
                <li>Pierwszy rodzaj gromadzonych informacji to informacje, które Administrator otrzymuje od osób
                    odwiedzających stronę internetową lub korzystające z usług świadczonych przez Administratora drogą
                    elektroniczną:&nbsp;Administrator otrzymuje i przechowuje wszelkie informacje przekazane przez osoby
                    korzystające z usług oferowanych przez Administratora.&nbsp; Osoba może zrezygnować z podania
                    określonych informacji, jednak wówczas możesz nie mieć dostępu do wielu spośród oferowanych przez
                    Administratora usług. Informacje te są przekazywane podczas:
                    <ul>
                        <li>wyszukiwania produktów;</li>
                        <li>składania zamówienia;</li>
                        <li>kontaktu z nami drogą telefoniczną, pocztą elektroniczną lub innymi kanałami;</li>
                        <li>wypełniania kwestionariuszy, zgłoszeń lub formularzy konkursowych;</li>
                    </ul>
                </li>
                <li>Przykładowo Administrator gromadzi następujące informacje: Imię, nazwisko, adres, numer telefonu,
                    dane dot. płatności, wiek, datę i miejsce urodzenia, dane osób, do których wysyłane są zakupione
                    produkty, adres e-mail, treść
                    recenzji i wiadomości e-mail wysyłanych do Administratora, informacje dotyczące tożsamości i
                    statusu, numery NIP oraz pliki logowania.
                </li>
                <li>Drugi rodzaj gromadzonych inforamcji to informacje otrzymywane automatycznie:&nbsp;określone rodzaje
                    informacji Administrator otrzymuje i przechowuje automatycznie podczas korzystania z oferowanych
                    przez Administratora usług. Administrator korzysta z plików "cookies" oraz gromadzi określonego
                    rodzaju informacje w czasie, gdy przeglądarka internetowa lub urządzenie korzysta z dostępu do usług
                    oferowanych przez Administratora.
                </li>
                <li>Wśród przykładowych informacji gromadzonych i analizowanych przez Administratora wymienić można:
                    <ul>
                        <li>adres protokołu internetowego (IP) użyty do połączenia komputera lub innego urządzenia z
                            Internetem;
                        </li>
                        <li>adres e-mail;</li>
                        <li>informacje o komputerze, urządzeniu i połączeniu, takie jak aplikacja na urządzeniu lub typ
                            i wersja przeglądarki, rodzaje i wersje wtyczek przeglądarki, system operacyjny lub
                            ustawienia strefy czasowej;
                        </li>
                        <li>lokalizacja komputera lub innego urządzenia;</li>
                        <li>historia zakupów i korzystania z treści;</li>
                        <li>pełna lista jednolitych adresów zasobów (Uniform Resource Locators) (URL) użytych w trakcie
                            sesji, numer pliku cookies, produkty lub treści przeglądane lub poszukiwane, czasy reakcji
                            stron, błędy przy pobieraniu, informacje o interakcji na stronie (przewijanie, klikanie,
                            zmiana grafiki po najechaniu kursorem myszki (tzw. mouse-over);
                        </li>
                    </ul></li>
            </ol>
            <h3 className="font-weight-bold">COOKIES</h3>
            <ol>
                <li>Cookies to małe pliki tekstowe zapisywane na komputerze użytkownika lub innym urządzeniu mobilnym, w
                    czasie korzystania przez niego z serwisu internetowego. Pliki te służą m.in. korzystaniu z różnych
                    funkcji przewidzianych na danej stronie internetowej lub potwierdzeniu, że dany użytkownik widział
                    określone treści z danej witryny internetowej. Wśród plików cookies wyróżnić możne te, które są
                    niezbędne do działania usług oferowanych przez Administratora. Należące do tej kategorii pliki
                    cookies wykorzystywane są, aby zapewnić:
                    <ul>
                        <li>utrzymanie sesji użytkownika;</li>
                        <li>zapisanie stanu sesji użytkownika;</li>
                        <li>umożliwienie autoryzacji z wykorzystaniem Serwisu logowania;</li>
                        <li>zapisanie informacji niezbędnych do działania koszyka internetowego w przypadku zakupów
                            internetowych;
                        </li>
                        <li>monitoring dostępności usług.</li>
                    </ul></li>
                <li>Kolejna kategoria plików cookies, to pliki, które wprawdzie nie są niezbędne do korzystania z usług
                    oferowanych przez Administratora, ale ułatwiają korzystanie z nich. Wykorzystuje się je, aby
                    umożliwić:
                    <ul>
                        <li>przywrócenie ostatnio odwiedzanego widoku przy następnym zalogowaniu do produktu;</li>
                        <li>przywrócenie sesji użytkownika;</li>
                        <li>sprawdzenie czy zapis do plików cookies funkcjonuje prawidłowo;</li>
                        <li>umożliwienie automatycznego zalogowania do usługi (opcja "zapamiętaj mnie");</li>
                        <li>wyświetlenie ostatnio przeglądanych produktów w sklepie internetowym.</li>
                    </ul></li>
                <li>Administrator korzysta też z usług podmiotów trzecich, które wykorzystują pliki cookies w
                    następujących celach:
                    <ul>
                        <li>monitorowanie ruchu na stronie WWW Administratora;</li>
                        <li>zbieranie anonimowych, zbiorczych statystyk;</li>
                        <li>kontrolowanie jak często pokazywana jest użytkownikom wybrana treść;</li>
                        <li>badanie zapisów na newslettery;</li>
                        <li>wykorzystanie narzędzia do komunikacji;</li>
                        <li>integracja z portalem społecznościom;</li>
                        <li>płatności internetowe.</li>
                    </ul></li>
                <li>Użytkownik może zarządzać plikami cookies zmieniając ustawienia swojej przeglądarki internetowej.
                    Administrator zastrzega jednocześnie, że po odrzuceniu plików cookies niektóre funkcje oferowane
                    przez Administratora mogą nie działać prawidłowo, a nawet w niektórych przypadkach wiąże się to z
                    całkowitym uniemożliwieniem z nich korzystania.
                </li>
            </ol>
            <h2 className="font-weight-bold">AUTOMATYCZNE PRZETWARZANIE DANYCH OSOBOWYCH</h2>
            <ol>
                <li>Administrator nie będzie podejmował zautomatyzowanych decyzji, w tym decyzji będących wynikiem
                    profilowania.
                </li>
            </ol>
            <h3 className="font-weight-bold">PODSTAWY PRAWNE PRZETWARZANIA</h3>
            <ol>
                <li>W przypadku przetwarzania danych osobowych w związku z korzystaniem przez użytkowników z usług
                    oferowanych przez Administratora mamy do czynienia z różnymi rodzajami podstaw prawnych
                    przetwarzania. I tak na przykład:
                    <ul>
                        <li>dane osobowe osób odwiedzających stronę internetową przetwarzane są na podstawie prawnie
                            uzasadnionego interesu Administratora danych np. na podstawie zgody w przypadku, gdy o
                            wyrażenie takiej zgody Administrator zwrócił się do osoby, której dane dotyczą;
                        </li>
                        <li>dane osobowe osób, które korzystają ze sklepu internetowego i wypełniają w tym celu
                            formularz internetowy Administrator przetwarza, ponieważ jest to niezbędne dla celu
                            wykonania umowy;
                        </li>
                        <li>niekiedy przepisy prawa wymagają od przetwarzania pewnych danych osobowych dla celów
                            podatkowych i rachunkowych.
                        </li>
                    </ul>
                </li>
                <li>Od osób fizycznych, które kontaktują się z Administratorem w celu uzyskania informacji o ofercie lub
                    podzielenia się uwagami dotyczącymi usług lub produktów, a także kontaktujących się w celu zawarcia
                    umowy z Administratorem, Administrator zbiera następujące dane osobowe: imię, nazwisko, adres, numer
                    telefonu, adres e-mail.
                </li>
                <li>Powyższe dane Administrator zbiera w oparciu o:
                    <ul>
                        <li>zgodę wyrażoną przez użytkownika kierującego powyższą prośbę do Administratora lub</li>
                        <li>w celu wykonania umowy (realizacji żądania) zgłoszonego przez daną osobę.</li>
                    </ul>
                </li>
                <li>Dostarczone dane Administrator przetwarza również na podstawie usprawiedliwionego celu
                    administratora danych.
                    Administrator przetwarza również dane osobowe swoich klientów i potencjalnych klientów. Wśród tych
                    danych mogą znajdować się również dane osobowe osób związanych z klientami Administratora
                    niebędącymi osobami fizycznymi (np. osoby kontaktowe). Dane osobowe tego rodzaju są przetwarzane w
                    systemach informatycznych, którymi posługuje się Administrator. Dane osobowe przetwarzane na te
                    potrzeby obejmują między innymi: imię, nazwisko, adres, numer telefonu, miejsce i datę urodzenia (w
                    przypadku zakupywania certyfikowanych kursów on-line), dane osób, do których
                    wysyłane są zakupione produkty, adres e-mail, numery NIP, dane dot. płatności.
                </li>
                <li>Przetwarzanie danych osobowych osób fizycznych będących klientami Administratora opiera się na:
                    <ul>
                        <li>usprawiedliwionym interesie administratora danych (np. marketingu bezpośredniego produktów
                            własnych, zabezpieczeniu dokumentacji na potrzeby obrony przed ewentualnymi roszczeniami lub
                            na potrzeby dochodzenia roszczeń);
                        </li>
                        <li>zgodzie (w tym w szczególności zgody na e-mail marketing lub telemarketing);</li>
                        <li>wykonaniu zawartej umowy (wystawienie certyfikatu za kurs on-line);</li>
                        <li>obowiązków wynikających z prawa (np. prawa podatkowego lub przepisów o rachunkowości).</li>
                    </ul>
                </li>
                <li>Przetwarzanie danych osobowych osób fizycznych będących potencjalnymi klientami opiera się na:
                    <ul>
                        <li>usprawiedliwionym interesie administratora danych (np. w zakresie marketingu bezpośrednim
                            produktów własnych);
                        </li>
                        <li>zgody (w tym w szczególności zgody na e-mail marketing lub telemarketing).</li>
                    </ul>
                </li>
            </ol>
            <h3 className="font-weight-bold">DO JAKICH CELÓW SĄ PRZETWARZANE DANE?</h3>
            <ol>
                <li>Dane osobowe przetwarzane są wyłącznie dla celów związanych z realizacją umowy, w tym wystawienia
                    certyfikatu za odbycie kursu on-line oraz do podjęcia
                    niezbędnych działań przed zawarciem umowy (np. przyjmowanie zamówień, świadczenia usług, obsługi
                    zleceń i wniosków, rozpatrywania reklamacji); wypełnienia prawnych obowiązków ciążących na
                    administratorze danych (np. przechowywanie dokumentacji podatkowej); wynikających z prawnie
                    uzasadnionych interesów realizowanych przez Administratora (np. marketing bezpośredni własnych
                    produktów, zabezpieczenie i dochodzenie roszczeń, zabezpieczenie i ochrona przed roszczeniami ze
                    strony użytkownika lub osób trzecich); marketingowych, nie wynikających z prawnie usprawiedliwionych
                    interesów realizowanych przez Administratora (np. marketing usług i produktów podmiotów trzecich,
                    marketing własny niebędący marketingiem bezpośrednim).
                </li>
            </ol>
            <h3 className="font-weight-bold">JAK DŁUGO ADMINISTRATOR PRZETWARZA DANE OSOBOWE?</h3>
            <ol>
                <li>Czas, przez jaki Administrator może przetwarzać dane osobowe, jest uzależniony od podstawy prawnej
                    stanowiącej legalną przesłankę przetwarzania danych osobowych. I tak:
                    <ul>
                        <li>w przypadku gromadzenia danych osobowych na potrzeby realizacji zamówienia lub zawarcia
                            umowy (podstawa prawna: art. 6 ust. 1 lit. b RODO): od momentu zebrania danych przed
                            zawarciem umowy w celu jej zawarcia lub od momentu zebrania tych danych w trakcie zawierania
                            umowy lub od momentu zebrania tych danych w trakcie trwania umowy (w razie uzupełnienia lub
                            aktualizacji danych w trakcie trwania umowy) do czasu rozwiązania umowy lub realizacji umowy
                            po momencie jej rozwiązania (np. rozpatrywanie reklamacji);
                        </li>
                        <li>w przypadku gromadzenia danych osobowych w celu wypełniania obowiązków wynikających z
                            przepisów prawa lub w związku z realizacją zadań w interesie publicznym (podstawa prawna:
                            art. 6 ust. 1 lit. c) przez okres realizacji obowiązków i zadań wynikających z
                            poszczególnych przepisów prawa;
                        </li>
                        <li>w przypadku przetwarzania danych osobowych w celach wynikających z prawnie uzasadnionych
                            interesów Administratora (podstawa prawna: art. 6 ust. 1 lit. f) dane będą przechowywane nie
                            dłużej niż w okresie 10 lat od momentu rozwiązania umowy lub do momentu wniesienia
                            uzasadnionego sprzeciwu wobec przetwarzania w takim celu;
                        </li>
                        <li>w przypadku gromadzenia danych osobowych na podstawie zgody (podstawa prawna: art. 6 ust. 1
                            lit. a lub f RODO): od momentu wyrażenia zgody na przetwarzanie danych nią objętych (również
                            w trakcie realizacji umowy) do momentu realizacji wniosku o wycofanie tej zgody, w przypadku
                            jej wycofania, lub do momentu odnotowania sprzeciwu;
                        </li>
                        <li>poza sytuacjami wymienionymi powyżej dane będą mogły być przechowywane w okresie
                            ograniczenia przetwarzania tych danych ustanowionego na Twój wniosek, wniosek organu
                            nadzorczego – w sytuacjach przewidzianych przez RODO w art. 18 oraz art. 58.
                        </li>
                    </ul>
                </li>
            </ol>
            <h3 className="font-weight-bold">KIEDY I W JAKI SPOSÓB ADMINISTRATOR UDOSTĘPNIA DANE OSOBOWE PODMIOTOM
                TRZECIM? CZY PRZEKAZUJE DANE DO PAŃSTW TRZECICH?</h3>
            <ol>
                <li>Administrator przekazuje dane osobowe innym wyłącznie, gdy zezwalają na to przepisy prawa. W takim
                    przypadku, w stosownej umowie zawieranej z podmiotem trzecim Administrator przewiduje postanowienia
                    i mechanizmy bezpieczeństwa w celu ochrony danych oraz zachowania naszych standardów w zakresie
                    ochrony danych, ich poufności i bezpieczeństwa. Umowy tego rodzaju zwane są umowami powierzenia
                    przetwarzania danych osobowych, a Administrator ma kontrolę nad tym, w jaki sposób i w jakim
                    zakresie podmiot, któremu Administrator powierzył przetwarzanie określonych kategorii danych
                    osobowych dane te przetwarza. W związku z powyższym Administrator wskazuje, że odbiorcami danych
                    osobowych, które Administrator przetwarza mogą być:
                    <ul>
                        <li>osoby upoważnione przez użytkownika;</li>
                        <li>upoważnione przez Administratora osoby zatrudnione u Administratora;</li>
                        <li>podmioty przetwarzające dane na zlecenie i w imieniu Administratora oraz osoby upoważnione,
                            zatrudnione w tych podmiotach (np. windykacja należności i dochodzenie roszczeń w oparciu o
                            usługi firm zewnętrznych);
                        </li>
                        <li>podmioty prowadzące działalność płatniczą oraz pośredniczące w płatnościach (banki,
                            instytucje płatnicze);
                        </li>
                        <li>podmioty prowadzące działalność pocztową lub kurierską;</li>
                        <li>strony trzecie - w przypadku korzystania przez Administratora z przysługujących praw (np. w
                            razie cesji wierzytelności wraz z przekazaniem danych osobowych związanych z
                            wierzytelnością);
                        </li>
                        <li>organy publiczne, które mogą otrzymywać dane w przypadkach innych niż w ramach konkretnego
                            postępowania prowadzonego zgodnie z prawem Unii lub prawem polskim.
                        </li>
                    </ul>
                </li>
            </ol>
            <h3 className="font-weight-bold">JAKIE PRAWA PRZYSŁUGUJĄ PODMIOTOM, KTÓRYCH DANE DOTYCZĄ I JAKI JEST SPOSÓB
                ICH REALIZACJI?</h3>
            <ol>
                <li>Podmiotom, których dane dotyczą przysługują następujące prawa:
                    <ul>
                        <li>prawo żądania dostępu do treści jego danych osobowych, ich sprostowania, usunięcia,
                            ograniczenia przetwarzania. Dodatkowo prawo wniesienia sprzeciwu wobec przetwarzania danych
                            oraz prawo do przenoszenia danych. Realizacja wymienionych w niniejszym akapicie praw odbywa
                            się zgodnie z przepisami ogólnego rozporządzenia o ochronie danych (RODO) – w oparciu o
                            definicje i mechanizmy opisane w tym rozporządzeniu;
                        </li>
                        <li>w przypadku, gdy Administrator przetwarza dane osobowe na podstawie zgody, prawo do
                            cofnięcia zgody w dowolnym momencie, bez wpływu na zgodność z prawem przetwarzania, którego
                            dokonano na podstawie zgody przed jej cofnięciem;
                        </li>
                        <li>prawo wniesienia skargi do organu nadzorczego na zasadach określonych przez ogólne
                            rozporządzenie o ochronie danych, a w szczególności na podstawie art. 77 tego
                            rozporządzenia. W Polsce Organem nadzorczym od 25 maja 2018 r. jest Urząd Ochrony Danych
                            Osobowych.
                        </li>
                    </ul>
                </li>
                <li>Administrator odpowiada za realizację praw zgodnie z obowiązującymi przepisami prawa. W przypadku
                    wszelkich pytań i próśb dotyczących zakresu i realizacji praw, a także w celu skontaktowania się z
                    Administratorem w celu skorzystania z określonego uprawnienia w zakresie ochrony danych osobowych
                    Administrator prosi o kontakt pod adresem e-mail: kontakt@edu-sense.pl
                </li>
                <li>Osoby fizyczne mają prawo ograniczenia przetwarzania lub wniesienia sprzeciwu wobec przetwarzania
                    swoich danych osobowych w dowolnej chwili, ze względu na ich szczególną sytuację, chyba że
                    przetwarzanie jest wymagane zgodnie z przepisami prawa.
                </li>
                <li>Osoba fizyczna może wnieść sprzeciw wobec przetwarzania jej danych osobowych, gdy:
                    <ul>
                        <li>przetwarzanie danych osobowych odbywa się na podstawie prawnie uzasadnionego interesu lub
                            dla celów statystycznych, a sprzeciw jest uzasadniony przez szczególną sytuację, w której
                            się znalazła,
                        </li>
                        <li>dane osobowe przetwarzane są na potrzeby marketingu bezpośredniego, w tym są profilowane dla
                            tego celu.
                        </li>
                    </ul>
                </li>
                <li>Z kolei w odniesieniu do żądania ograniczenia przetwarzania danych to przysługuje ono np. gdy dana
                    osoba zauważy, że jej dane są nieprawidłowe. Wówczas może żądać ograniczenia przetwarzania danych na
                    okres pozwalający Administratorowi sprawdzić prawidłowość tych danych.
                </li>
                <li>Z prawa do usunięcia danych można skorzystać np. gdy dane osoby fizycznej nie będą już niezbędne do
                    celów, dla których zostały zebrane przez Administratora, albo gdy osoba fizyczna wycofa swoją zgodę
                    na przetwarzanie danych przez Administratora. Nadto, w przypadku, gdy osoba fizyczna zgłosi sprzeciw
                    wobec przetwarzania jej danych lub gdy jej dane będą przetwarzane niezgodnie z prawem. Dane powinny
                    być również usunięte w celu wywiązania się z obowiązku wynikającego z przepisu prawa.
                </li>
                <li>Z kolei prawo do przenoszenia danych przysługuje wówczas, gdy przetwarzanie danych osoby odbywa się
                    na podstawie zgody osoby fizycznej lub umowy zawartej z nią oraz gdy przetwarzanie to odbywa się w
                    sposób automatyczny.
                </li>
            </ol>
            <h3 className="font-weight-bold">WSZELKIE POZOSTAŁE PYTANIA, WĄTPLIWOŚCI I SKARGI</h3>
            <ol>
                <li>W razie, gdybyście mieli Państwo jakiekolwiek pytania, zastrzeżenia lub wątpliwości dotyczące treści
                    niniejszej Polityki prywatności lub sposobu w jaki Administrator przetwarza dane osobowe, jak
                    również skargi dotyczące tych kwestii prosimy o przesłanie maila wraz ze szczegółowymi informacjami
                    dotyczącymi skargi na adres: kontakt@edu-sense.pl
                </li>
                <li>Wszelkie otrzymane skargi zostaną rozpatrzone i Administrator udzieli na nie odpowiedzi. Osoby,
                    których dane osobowe przetwarza Administratora, mają również prawo wnieść skargę do organu
                    nadzorczego, którym jest Urząd Ochrony Danych Osobowych (adres: ul. Stawki 2, 00-193 Warszawa).
                </li>
                <li>Kontakt z Administratorem możliwy jest również bezpośrednio w siedzibie Administratora, a także
                    kontakt listowy (pocztą) lub kontakt telefoniczny i w tym celu Administrator podaje poniższe dane
                    kontaktowe: EduSense SA, ul. Kieturakisa 10/7, 80-742 Gdańsk, kontakt@edu-sense.pl telefon: +
                    48 585 055 914.
                </li>
                <li>Administrator zobowiązuje się do regularnego przeglądu niniejszej Polityki prywatności i jej zmiany
                    w sytuacji, gdy okaże się to niezbędne lub pożądane z uwagi na: nowe przepisy prawa, nowe wytyczne
                    organów odpowiedzialnych za nadzór nad procesami ochrony danych osobowych lub najlepsze praktyki
                    stosowane w obszarze ochrony danych osobowych. Administrator zastrzega sobie również możliwość
                    zmiany niniejszej Polityki prywatności w przypadku zmian technologii, przy wykorzystaniu której
                    przetwarza dane osobowe (o ile zmiana jej wpływa na brzmienie niniejszego dokumentu), a także w
                    razie zmiany sposobów, celów lub podstaw prawnych przetwarzania danych osobowych.
                </li>
            </ol>
        </PrivacyWrapper>
    );
}
