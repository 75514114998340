import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import {
    BrowserRouter as Router, Redirect, Route, Switch,
} from 'react-router-dom';
import AppContext from './context/app-context';

import Home from './client/pages/Home/Home';
import Products from './client/pages/Products/Products';
import Product from './client/pages/Product/Product';
import Company from './client/pages/Company/Company';
import Faq from './client/pages/Faq/Faq';
import Courses from './client/pages/Courses/Courses';
import Course from './client/pages/Course/Course';
import Contact from './client/pages/Contact';
import Cart from './client/pages/Cart/Cart';
import Content from './client/Content';
import Header from './client/Header/Header';
import Footer from './client/Footer/Footer';
import ScrollToTop from './client/ScrollToTop';
import useCart from './client/Cart/hook/useCart';
import Regulations from './client/pages/Regulations/Regulations';
import Warranty from './client/pages/Warranty/Warranty';
import Privacy from './client/pages/Privacy/Privacy';
import Certifications from './client/pages/Certifications/Certifications';
import CartContext from './client/Cart/context/cartContext';
import NotificationContext from './client/NotificationContainer/context/NotificationContext';
import NotificationContainer from './client/NotificationContainer/NotificationContainer';
import OrderPreview from './client/pages/OrderPreview/OrderPreview';
import CookieNotification from './client/CookieNotification/CookieNotification';
import NotFound from './client/pages/NotFound/NotFound';
import Redirects from './client/pages/Redirects';
import Events from './client/pages/Events/Events';
import Gtag from './client/Gtag/Gtag';
import LabProducts from './client/pages/LabProducts/LabProducts';
import LabProduct from './client/pages/LabProduct/LabProduct';
import SearchResults from './client/pages/SearchResults/SearchResults';
import store from './store';
import ProductsForPreschool from './client/pages/ProductsForPreschool/ProductsForPreschool';
import ProductsForSchool from './client/pages/ProductsForSchool/ProductsForSchool';
import { ANY_PAGE_THAT_WILL_BE_REPLACED_IN_CONTENT } from './anyPage';
import OfferCatalog from "./client/pages/OfferCatalog/OfferCatalog";
import AktywnaTablicaProducts from "./client/pages/AktywnaTablicaProducts/AktywnaTablicaProducts";
import AktywnaTablicaProduct from "./client/pages/AktywnaTablicaProduct/AktywnaTablicaProduct";
import UEProjects from "./client/pages/UEProjects/UEProjects";

function App() {
    const defaultLang = 'pl';
    const defaultRoute = '/';
    const defaultPage = 'home';

    const availableLangs = ['pl', 'en'];

    const hrefData = document.location.pathname.match('^/([a-z]{2})(/?[^/]*)');
    if (!hrefData) {
        document.location.href = defaultRoute + defaultLang;
    }

    if (!availableLangs.find((value) => value === hrefData[1])) {
        hrefData[1] = 'pl';
    }

    const routeMap = {
        offer: { name: 'offer', path: { pl: '/oferta/:number', en: '/offer/:number' } },
        events: { name: 'events', path: { pl: '/warsztaty', en: '/events' } },
        order_preview: { name: 'order_preview', path: { pl: '/zamowienie', en: '/order' } },
        product: { name: 'product', path: { pl: '/produkty/:product', en: '/products/:product' } },
        products: { name: 'products', path: { pl: '/produkty', en: '/products' } },
        product_for_preschool: { name: 'product_for_preschool', path: { pl: '/dla-przedszkoli/:product', en: '/for-preschool/:product' } },
        products_for_preschool: { name: 'products_for_preschool', path: { pl: '/dla-przedszkoli', en: '/for-preschool' } },
        product_for_school: { name: 'product_for_school', path: { pl: '/dla-szkol/:product', en: '/for-school/:product' } },
        products_for_school: { name: 'products_for_school', path: { pl: '/dla-szkol', en: '/for-school' } },
        course: { name: 'course', path: { pl: '/szkolenia/:product', en: '/courses/:product' } },
        courses: { name: 'courses', path: { pl: '/szkolenia', en: '/courses' } },
        lab: { name: 'lab', path: { pl: '/laboratoria-przyszlosci/:product', en: '/labs/:product' } },
        labs: { name: 'labs', path: { pl: '/laboratoria-przyszlosci', en: '/labs' } },
        aktywna_tablica_product: { name: 'aktywna_tablica_product', path: { pl: '/aktywna_tablica/:product', en: '/aktywna_tablica/:product' } },
        aktywna_tablica_products: { name: 'aktywna_tablica_products', path: { pl: '/aktywna_tablica', en: '/aktywna_tablica' } },
        search: { name: 'search', path: { pl: '/szukaj', en: '/search' } },
        company: { name: 'company', path: { pl: '/firma', en: '/company' } },
        contact: { name: 'contact', path: { pl: '/#contact', en: '/#contact' } },
        faq: { name: 'faq', path: { pl: '/pomoc', en: '/help' } },
        cart: { name: 'cart', path: { pl: '/koszyk/:hash?', en: '/cart/:hash?' } },
        regulations: { name: 'regulations', path: { pl: '/regulamin', en: '/regulations' } },
        certifications: { name: 'certifications', path: { pl: '/certyfikaty', en: '/certifications' } },
        warranty: { name: 'warranty', path: { pl: '/gwarancja', en: '/warranty' } },
        privacy: { name: 'privacy', path: { pl: '/polityka-prywatnosci', en: '/privacy-policy' } },
        ue_projects: { name: 'ue_projects', path: { pl: '/projekty-unijne', en: '/ue-projects' } },
        redirects: { name: 'redirects', path: { pl: '/redirects', en: '/redirects' } },
        notfound: {
            name: 'notfound',
            path: {
                pl: `/:${ANY_PAGE_THAT_WILL_BE_REPLACED_IN_CONTENT}`,
                en: `/:${ANY_PAGE_THAT_WILL_BE_REPLACED_IN_CONTENT}`,
            },
        },
        home: { name: 'products', path: { pl: '/', en: '/' } },
    };
    const components = {
        product_for_preschool: Product,
        products_for_preschool: Products,
        // products_for_preschool: ProductsForPreschool,
        product_for_school: Product,
        products_for_school: Products,
        // products_for_school: ProductsForSchool,
        offer: OfferCatalog,
        events: Events,
        order_preview: OrderPreview,
        products: Products,
        product: Product,
        courses: Courses,
        course: Course,
        labs: LabProducts,
        lab: LabProduct,
        aktywna_tablica_products: AktywnaTablicaProducts,
        aktywna_tablica_product: AktywnaTablicaProduct,
        search: SearchResults,
        company: Company,
        contact: Contact,
        faq: Faq,
        cart: Cart,
        home: Home,
        regulations: Regulations,
        certifications: Certifications,
        warranty: Warranty,
        privacy: Privacy,
        ue_projects: UEProjects,
        notfound: NotFound,
        redirects: Redirects,
    };
    const [lang] = useState(hrefData[1] || defaultLang);
    const hrefPage = Object.values(routeMap).find((r) => r.path[lang] === hrefData[2]);
    const [page, setPage] = useState(hrefPage ? hrefPage.name : defaultPage);
    const [routePage, setRoutePage] = useState(hrefPage ? hrefPage.path[lang] : defaultRoute);
    const [cartItemsCount, setCartItemsCount] = useCart().useItemsCountState();
    const [numberOfActiveStep, setNumberOfActiveStep] = useState('1');
    const [notification, setNotification] = useState(null);
    const [googleData, setGoogleData] = useState({ ecomm_pagetype: 'other' });
    const [breakingNews, setBreakingNews] = useState(null);

    const notify = (text, type) => setNotification({ text, type });

    useEffect(() => {
        window.dataLayer.push(googleData);
    }, [googleData]);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://static.inteliwise.com/embed/1cbc0924b2106404efdca4e325a6110904584fdf16c0024b6d904a29ef123a5a/1945/space.js';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <Router basename={`${lang}`}>
            <Gtag />
            <Provider store={store}>
                <AppContext.Provider value={{
                    lang,
                    page,
                    setPage,
                    routePage,
                    setRoutePage,
                    routeMap,
                    googleData,
                    setGoogleData,
                    breakingNews,
                    setBreakingNews,
                }}
                >
                    <NotificationContext.Provider value={{ notify, notification }}>
                        <NotificationContainer />
                        <CookieNotification />
                        <CartContext.Provider value={{
                            cartItemsCount, setCartItemsCount, numberOfActiveStep, setNumberOfActiveStep,
                        }}
                        >
                            <ScrollToTop>
                                <div
                                    className="App with-breaking-news"
                                    style={{
                                        backgroundImage: (routePage === '/produkty' || routePage === '/szkolenia')
                                            && `url(${process.env.REACT_APP_CDN_URL}/christmas/bg_swieta_pattern_verA.png) 0 410px`,
                                    }}
                                >
                                    <Header />
                                    <Switch>
                                        <Redirect from="/produkty/:category/:slug" to="/produkty/:slug" />
                                        <Redirect from="/szkolenia/:category/:slug" to="/szkolenia/:slug" />
                                    </Switch>
                                    <Content>
                                        <Switch>
                                            {Object.values(routeMap).map((r) => {
                                                const path = r.path ? r.path[lang] : null;
                                                return (
                                                    <Route
                                                        exact
                                                        key={r.name}
                                                        path={path}
                                                        component={components[r.name]}
                                                    />
                                                );
                                            })}
                                        </Switch>
                                    </Content>
                                    <Footer />
                                </div>
                            </ScrollToTop>
                        </CartContext.Provider>
                    </NotificationContext.Provider>
                </AppContext.Provider>
            </Provider>
        </Router>
    );
}

export default App;
