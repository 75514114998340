import styled from 'styled-components';
import PriceMain from '../../PriceMain/PriceMain';

export const StyledProduct = styled.a`
    width: 100%;
    display: grid;
    grid-template-columns: auto 1fr auto auto;
    grid-template-areas: 'image name oldPrice Price';
    grid-gap: 16px;
    align-items: center;
    padding: 8px 16px;
    border-bottom: 1px solid #dddddd;
    color: #00437E;
    font-size: 15px;
    
    @media (max-width: 991px){
        row-gap: 0;
        grid-template-areas: 
            'image name name name'
            '. .  oldPrice Price';
    }
`;

export const Image = styled.img`
    width: 100%;
    height: auto;
    grid-area: image;
    
     @media (max-width: 991px){
        align-self: start;
    }
`;

export const Name = styled.p`
    line-height: 1.1;  
    font-weight: 500;
    text-align: left;
    margin: 0;
    grid-area:name;
`;

export const OldPrice = styled(PriceMain)`
    grid-area: oldPrice;
    
    @media (max-width: 991px){
        justify-self: end;    
    }
`;

export const Price = styled(PriceMain)`
    grid-area: Price;
`;

export const ImageContainer = styled.div`
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
`;
