export const customerServiceOfficeData = {
    name: 'Biuro obsługi klienta',
    employees: [
        {
            imageUrl: 'foto_Ania.png',
            name: 'Anna Raczkowska',
            workplace: 'Specjalista ds. obsługi klienta',
            telephone: '+48 585 055 914',
            email: 'kontakt@edu-sense.pl',
        },
        {
            imageUrl: 'foto_Magda.png',
            name: 'Magdalena Osiak',
            workplace: 'Specjalista ds. obsługi klienta',
            telephone: '+48 585 055 914',
            email: 'kontakt@edu-sense.pl',
        },
    ],
};

export const salesDepartmentData = {
    name: 'Dział sprzedaży',
    employees: [
    ],
};
